import { gql } from "@apollo/client";

export const REFINANCE_LEAD_LIST_QUERY = (filterData) => {
  return gql`
    query {
      refinanceLeadList(
        page_no: ${filterData.page_no},
        user_id: ${filterData.user_id},
        role_id: 1,
        filterParams: {
          ${filterData.search_type ? `, search_type: "${filterData.search_type}"` : ''}
          ${filterData.search_text ? `, search_text: "${filterData.search_text}"` : ''}
          ${filterData.order_by ? `, order_by: "${filterData.order_by}"` : ''}
          ${filterData.financier_id?.length ? `, financier_id: [${filterData.financier_id.join(',')}]` : ''}
          ${filterData.status_id?.length ? `, status_id: [${filterData.status_id.join(',')}]` : ''}
          ${filterData.sub_status_id?.length ? `, sub_status_id: [${filterData.sub_status_id.join(',')}]` : ''}
          ${filterData.source?.length ? `, source: [${filterData.source.map(s => `"${s}"`).join(',')}]` : ''}
          ${filterData.lead_source_role !== undefined ? `, lead_source_role: ${filterData.lead_source_role}` : ''}
          ${filterData.lead_source_users?.length ? `, lead_source_users: [${filterData.lead_source_users.join(',')}]` : ''}
          ${filterData.payment_status ? `, payment_status: "${filterData.payment_status}"` : ''}
          ${filterData.is_on_hold !== undefined ? `, is_on_hold: "${filterData.is_on_hold}"` : ''}
          ${filterData.lead_source ? `, lead_source: "${filterData.lead_source}"` : ''}
          ${filterData.date_type ? `, date_type: "${filterData.date_type}"` : ''}
          ${filterData.from_date ? `, from_date: "${filterData.from_date}"` : ''}
          ${filterData.to_date ? `, to_date: "${filterData.to_date}"` : ''}
          ${filterData.request_type ? `, request_type: "${filterData.request_type}"` : ""}
          ${filterData.ageing_day ? `, ageing_day: "${filterData.ageing_day}"` : ""}
          ${filterData.payment_received_from_financier ? `, payment_received_from_financier: "${filterData.payment_received_from_financier}"` : ""}
          ${filterData.financier_sub_status ? `, financier_sub_status: "${filterData.financier_sub_status}"` : ''}
          ${filterData.dealership_category ? `, dealership_category: "${filterData.dealership_category}"` : ""}
          ${filterData.lead_created_by ? `, lead_created_by: "${filterData.lead_created_by}"` : ""}
          ${filterData.dealership_name ? `, dealership_name: "${filterData.dealership_name}"` : ""}
          ${filterData.dealer_incentive_received ? `, dealer_incentive_received: "${filterData.dealer_incentive_received}"` : ""}
        }
      ) {
          lead_list {
            id
            user_onboarded
            loan_id
            make
            model
            variant_name
            refinance_status
            refinance_sub_status
            customer_name
            vehicle_plate_number
            make_year
            mobile_number
            assigned_to_user_id
            status_name
            sub_status_name
            followup_date
            followup_time
            updated_date
            created_date
            financer_list {
                id
                financier_name
            }
            quote_data {
                id
                lead_id
                quote_id
                amount_financed
                tenure
                interest_rate
                instalment_amount
            }
            lead_source
            ageing_days
          }
          tab_counts {
            all_cases
            my_cases
            disbursed_cases
            open_cases
            new_cases
            lost_cases
          }
          pagination {
            total
            page_no
            total_pages
          }
        }
      }
    `;
};

export const GET_CUSTOMER_TIMELINE = (lead_id) => {
  return gql`
        query GetCustomerTimeline {
            getCustomerTimeline(
                lead_id: ${lead_id || 0}
            ) {
              customer_timeline {
                created_by
                status
                sub_status
                created_date
                created_time
              }
              financier_timeline {
                created_by
                status
                sub_status
                created_date
                created_time
                financier_name
                financier_id
              }
            }
        }
  `;
};

export const GET_UCRF_LEAD_DETAIL = (lead_id) => {
  return gql`
    query GetLeadDetail {
        getLeadDetail(lead_id: ${lead_id}) {
            lead_id
            user_name
            user_role
            assigned_to
            send_to_financier {
              financier_id
              financier_name
            }
            personal_details {
                personal_customer_name
                customer_mobile_number
                customer_alternative_mobile_no
                customer_email_id
                customer_date_of_birth
                customer_civil_status
                customer_type
                customer_educational_attainment
                customer_mother_name
                customer_father_name
                customer_gender
                customer_number_of_dependents
                customer_govt_id
            }
            basic_details {
                source_type
                customer_name
                user_id
                role_id
                customer_mobile_no
                date_of_birth
                vehicle_plate_number
                customer_govt_id
                agent_name
                agent_mobile_no
                loan_purpose
                source_description
                dealer_id
                created_date
                user_hierarchy {
                    aro_id
                    aro_name
                    agent_id
                    agent_name
                    sm_id
                    sm_name
                    th_id
                    th_name
                    nsh_id
                    nsh_name
                    president_id
                    president_name
                    so_id
                    so_name
                }
                case_status
                case_sub_status
                refinance_status
                refinance_sub_status
                doc_status
                lead_status
                dealer_name
                dealer_code
                created_by_type
                dealership_category
                user_onboarded
            }
            soft_approval_status {
                financier_id
                soft_approval_status
                receive_date
                attachments
                reject_reason
            }
            select_financier {
                financier_id
            }
            employee_details {
                name_of_employer
                business_address
                company_mobile_no
                company_office_telephone_no
                position
                gross_montly_income
                years_with_the_company
            }
            cra_residential_details {
                cra_house_number
                cra_street
                cra_region
                cra_city
                cra_barangay
                cra_zipcode
            }
            same_as_permanent_address {
                pa_sameas_cra
            }
            co_personal_details {
                co_name
                co_mobile_number
                co_alternative_mobile_no
                co_email_id
                co_date_of_birth
                co_civil_status
                co_relationship_with_borrower
                co_type
                co_educational_attainment
                co_mother_name
                co_father_name
                co_gender
                co_govt_id
            }
            co_permanent_address {
                co_pa_house_number
                co_pa_street
                co_pa_region
                co_pa_city
                co_pa_barangay
                co_pa_zipcode
            }
            co_same_as_permanent_address {
                co_pa_sameas_cra
            }
            co_cra_residential_details {
                co_cra_house_number
                co_cra_street
                co_cra_region
                co_cra_city
                co_cra_barangay
                co_cra_zipcode
            }
            co_employee_details {
                co_name_of_employer
                co_business_address
                co_company_mobile_no
                co_company_office_telephone_no
                co_position
                co_gross_montly_income
                co_years_with_the_company
            }
            permanent_address {
                pa_house_number
                pa_street
                pa_region
                pa_city
                pa_barangay
                pa_zipcode
                house_type
                duration_of_stay
            }
            documents {
                id
                lead_id
                doc_id
                parent_doc_id
                doc_path
            }
            financier_form{
                vehicle_usage
                vehicle_type
                vehicle_category
                make_id
                make_name
                model_id
                model_name
                variant_id
                variant_name
                make_year
                tenure_in_month
                car_fmv
            }
            leadfinancierList {
                financier_id
                financier_name
            }
            lead_template_history {
              id
              lead_id
              financier_id
              user_id
              template_type
              remarks
              doc_ids
              template_id
              user_email
              send_html_template
              page_type
              status
              created_by
              updated_by
              created_date
              updated_date
            }
            leaddocs {
              id
              lead_id
              doc_id
              parent_doc_id
              doc_path
              key_name
              name
              name_id
            }
            ci_schedule_data {
              financier_id
              lead_id
              ci_schedule_date
              reference_notes
              schedule_status
              is_skipped
            }
            ci_report_data {
              financier_id
              lead_id
              ci_completed_date
              reference_notes
              report_status
              is_skipped
            }
            contract_signing_data {
              financier_id
              lead_id
              contract_signing_date
              reference_notes
              attachments
            }
            loan_disbursement_data {
              lead_id
              loan_disbursement_date
              amount
              attachments
            }
            financier_loan_approval {
              car_details {
                  vehicle_usage
                  vehicle_category
                  vehicle_type
                  make_id
                  make_name
                  model_id
                  model_name
                  variant_id
                  variant_name
                  make_year
                  vehicle_plate_number
              }
              loan_details {
                  financier_id
                  is_financier_loan_approval_filled
                  additional_deductions {
                      ltp
                      cash_card
                      direct_deposit
                      proceed_charge
                      comprehensive_ins
                      handling_fee
                      pull_out_fee
                      extra_service
                      cancellation_fee
                      appraisal_fee
                      open_ca
                      total_additional_deductions
                  }
                  additional_loan_info {
                      loan_approved
                      report_status
                      financier_approval_docs
                  }
                  loan_calculations {
                      total_deductions
                      rebate
                      net_proceeds
                      monthly_amortization
                      pf_rate
                  }
                  loan_details {
                      type_of_loan
                      branch_name
                      loan_approval_date
                      loan_amount
                      interest_rate
                      interest_amount
                      term_in_months
                      total_loan_amount
                  }
                  standard_deductions {
                      processing_fee
                      chm_rem_fee
                      chm_tds_stamp
                      pn_doc_stamp
                      notarial_fee
                      crf
                      service_fee
                      transfer_of_ownership
                      bdo_on_site
                      lri
                      registration_fee
                      total_standard_deductions
                  }
                } 
            }
            computation_details {
            lead_id
            data_filled
            financier_id
            loan_amount
            pass_through {
                dealer_incentive_amount
                payout_percentage
                tax_percentage
                payout_amount
            }
            incentive_amount_from_financier {
                di_percentage
                di_amount
                di_tax_percentage
                di_tax_amount
            }
            computation_details {
                incentive_pct
                incentive_amount
                topup_pct
                topup_amount
                total_incentive_pct
                total_incentive_amount
                incentive_tax_pct
                incentive_net_tax
            }
          }
          payment_verification {
            is_payment_verification_filled
            tin_number
            accreditation_date
            total_incentive_amount
            total_incentive_amount_net_tax
            incentive_amount
            di_amount
            di_tax_amount
            source_type
            bank_details {
              bank_id
              bank_name
              branch_name
              account_name
              account_number
            }
            bank_account
          }
          incentive_paid {
            lead_id
            amount
            action_type
            created_date
            data_filled
          }
          incentive_received {
            lead_id
            amount
            action_type
            created_date
            data_filled
          }
          customer_timeline_status_ids
        }
    }
    
  `;
};

export const GET_UCRF_MENU_LIST = (lead_id) => {
  return gql`
        query GetNavigationMenu {
            getNavigationMenu {
                menu_name
                id
                child_data {
                    id
                    menu_name
                    status_url
                }
                status_url
            }
        }
    `;
};

export const GET_UCRF_FORM_FILL_STATUS = (params) => {
  return gql`
    query GetFormFillStatus {
        getFormFillStatus(lead_id: ${params.lead_id}) {
            active_page
            parent_active_menuId_arr
            parent_completed_menuId_arr
            menu_id_arr
        }
    }    
  `;
};


export const GET_FINANCIER_LIST = () => {
  return gql`
    query GetFinancierList {
        getFinancierList {
            id
            logo
            financier_name
        }
    }    
  `;
};

export const GET_MASTER_DATA_UCRF = () => {
  return gql`query MasterConfig {
      masterConfig {
        tenure_in_month {
          id
          label
          value
        }
        source_type {
          id
          label
          value
        }
        vehicle_type {
          id
          label
          value
          vehicle_usage
          vehicle_category
        }
        customer_govt_id {
          id
          label
          value
        }
        make_year {
          id
          label
          value
        }
        co_years_with_the_company {
          id
          label
          value
        }
        co_govt_id {
          id
          label
          value
        }
        co_gender {
          id
          label
          value
        }
        co_educational_attainment {
          id
          label
          value
        }
        co_type {
          id
          label
          value
        }
        co_relationship_with_borrower {
          id
          label
          value
        }
        co_civil_status {
          id
          label
          value
        }
        years_with_the_company {
          id
          label
          value
        }
        duration_of_stay {
          id
          label
          value
        }
        house_type {
          id
          label
          value
        }
        customer_number_of_dependents {
          id
          label
          value
        }
        customer_gender {
          id
          label
          value
        }
        customer_educational_attainment {
          id
          label
          value
        }
        customer_type {
          id
          label
          value
        }
        customer_civil_status {
          id
          label
          value
        }
        vehicle_usage {
          id
          label
          value
        }
        financier_list {
            id
            financier_name
            logo
        }
        noTouchPolicyFinancier
        bank_list {
            bankId
            id
            label: name
            value: id
            bankName
            valid_length
        }
      }
    }
  `
}
export const REFINANCE_TEMPLATE_LIST = (financer_ids) => gql`query{
    financierTemplateList(financer_ids: "${financer_ids || 0}") {
        template_id
        financier_id
        financier_group_id
        template_type
        template_body
        template_title
        lang_id
        financier_group_name
        financier_group_email
        financier_group_mobile
        template_for
        template_created_by
        template_updated_by
        template_created_date
        template_updated_date
    }
}`;


export const GET_REGION_CITY_LIST_REFINANCE = () => gql`
    query {
        getRegionCityList {
            state {
                id
                label: name
                value: id
            }
            city {
                id
                value: id
                label: name
                state_id
            }
        }
    }
`

export const GET_LOCALITY_LIST_REFINANCE = (city_id) => gql`
    query {
        getLocalityList(city_id: ${city_id}) {
            id
            label: name
            value: id
            zipcode: pin_code
        }
    }
`

export const SEND_TO_FINANCER_REFINANCE = gql`
  mutation saveSendToFinancer($sendToFinancerData: SendToFinancerData!){
    saveSendToFinancer(sendToFinancerData : $sendToFinancerData) {
      message
    }
  }
`

export const GET_REFINANCE_STATUS_LIST = (status_ids) => {
  return gql`
    query RefinanceStatusList {
    refinanceStatusList (status_ids: ${status_ids}) {
      id
      label: status_name
      value: id
      status_key
      parent_id
      position
      status
    }
  }
`};

export const GET_REFINANCE_SUB_STATUS_LIST = (status_ids) => {
  return gql`
    query RefinanceSubStatusList {
    refinanceSubStatusList(status_ids: ${status_ids}) {
      id
      label: status_name
      value: id
      status_key
      parent_id
      position
      status
    }
  }
`};


export const REFINANCE_DASHBOARD_QUERY = (user_id, role_id, view_type) => {
  return gql`
    query RefinanceLeadDashboard {
      refinanceLeadDashboard(user_id: ${user_id}, role_id: ${role_id}, view_type: "${view_type}") {
        lead_count
        name
        filter_params {
          doc_status
          status_id
          sub_status_id
          payment_status
        }
        card_styles {
          icon_className
          max_icon_paths
        }
      }
    }
  `;
};

export const UCRF_SAVE_LEAD_DETAILS = gql`
  mutation SaveLeadDetails(
    $tab_id: Float!,
    $user_id: Float!,
    $role_id: Float!,
    $basic_details: BasicLeadDetailInput!
  ) {
    saveLeadDetails(
      tab_id: $tab_id
      user_id: $user_id
      role_id: $role_id
      basic_details: $basic_details
    ) {
      lead_id
      is_duplicate
      message
    }
  }
`;

export const GET_MMV_ALL = (category_id) => {
  return gql`
    query GetMmvAll {
      getMmvAll (category_id: ${category_id}) {
        model {
          label: m
          rk
          mk_id
          id: p_m_id
          value: p_m_id
          s_year
          e_year
          category_id
        }
        make {
          id
          label: make
          value: id
          category_id
        }
        version {
          id: vn_id
          label: vn
          value: vn_id
          mk_id
          md_id
          s_year
          e_year
          f_type
          c_b_type
          tms
          mk
          md
          p_m_id
          p_m_n
          engine_capacity
          drive_type
          category_id
          yc
        }
      }
    }
  `
}

export const WEB_DOC_CONFIG_LIST_REFINANCE = (lead_id) => {
  return gql`
  query WebDocumentConfig {
    webDocumentConfig(lead_id: ${lead_id}) {
        id
        min_upload
        max_upload
        doc_type
        is_required
        customer_type
        is_co_borrower
        is_required_app
        show_in_app
        name
        doc_category
        doc_section
        child {
            id
            parent_id
            is_required
            max_upload
            min_upload
            doc_type
            name
            upload_doc
            financier_id
        }
    }
  }
  `
}

export const GET_SAVED_DOCUMENTS = (lead_id) => {
  return gql`query GetSavedDocuments {
      getSavedDocuments(lead_id: ${lead_id}) {
          id
          lead_id
          doc_id
          parent_doc_id
          doc_status
          doc_path
          financier_id
          ext
      }
    }
  `
}
export const LEADS_REMARK_QUERY_REFINANCE = (lead_id) => gql`
query{
  getRemarksList(lead_id:${lead_id}){
    remark
    created_date
    created_by_name
    section
    sub_section
  }
}
`

export const SAVE_REMARKS_UCRF = gql`
mutation saveRemarks($LeadData: RemarksData!){
  saveRemarks(remarks_data: $LeadData) {
      message 
    }
  }
`;

export const COMPUTATION_MASTER_DATA = (financier_id) => {
  return gql`query{
  computationMasterDetails(financier_id: ${financier_id}) {
    id
    financier_id
    car_age_start
    car_age_end
    vehicle_type
    amount_financed_min
    amount_financed_max
    term_months
    chattel_processing_fee
    interest_rates
    carmudi_incentive_pct
    tax_pct
    carmudi_incentive_net_tax_pct
    carmudi_top_up_pct
    top_up_tax_pct
    carmudi_top_up_net_tax_pct
    total_carmudi_incentive_pct
    total_incentive_tax_pct
    total_carmudi_incentive_net_tax_pct
    di_amount_financier_to_carmudi_pct
    di_financier_to_carmudi_tax_pct
    di_payment_from_fin_net
    financier
  }
}`;
};

export const SAVE_COMPUTATION_CONFIG = gql`
  mutation saveComputationConfig($fileComputationData: [ComputationConfigInput!]!) {
    saveComputationConfig(updates: $fileComputationData) {
      message
    }
  }
`;

export const GET_LOAN_EXPECTED_VEHICLE_DETAILS = (lead_id) => {
  return gql`query GetLeadVehicalDetails {
    getLeadVehicalDetails(lead_id: ${lead_id}) {
        vehicle_usage
        vehicle_type
        vehicle_category
        make_id
        make_name
        model_id
        model_name
        variant_id
        variant_name
        make_year
        tenure_in_month
        car_fmv
    }
}
  `
}

export const GET_LOAN_EXPECTED_FINANCIER_LIST = (lead_id) => {
  return gql`query RefinanceQuote {
    refinanceQuote(lead_id: "${lead_id}") {
        financier_id
        amount_financed
        mmv
        financier_name
        financier_logo
        fmv_of_car
        tenure
        instalment_amount
        interest_rate
        approval
        chattel_fee
        chattel_fee_age
        carmudi_incentive_age
        carmudi_incentive
        carmudi_incentive_tax_age
        carmudi_incentive_net_of_tax
        is_selected
    }
}

`
}

export const GET_LOAN_EXPECTED_QUOTE_FINANCIER_LIST = (lead_id) => {
  return gql`query Quote_financier_list {
    quote_financier_list(lead_id: "${lead_id}") {
        financier_id
        financier_name
        max_percent_age
        max_fmv_in_car
        payment_list {
            quote_id
            is_selected
            amount_financed
            tenure
            interest_rate
            instalment_amount
            fmv_of_car
            chattel_fee
            chattel_fee_age
            carmudi_incentive_age
            carmudi_incentive_amount
            carmudi_top_up_age
            carmudi_top_up_amount
            total_carmudi_incentive_age
            carmudi_incentive_tax_age
            total_carmudi_incentive_net_of_tax
            interest_rate_list {
                id
                label
                value
            }
            chattel_fee_age_list {
                id
                label
                value
            }
        }
    }
}
`
}

export const LEAD_LOST_FLOW_UCRF = gql`
  mutation LeadLostFlow($leadLostData: LeadLostFlow!){
     leadLostFlow(params: $leadLostData){
      message
     }
  }
`;
export const FINANCIER_QUOTE_RE_CALCULATION = (params) => {
  // console.log("====re_calculation params====",params);
  return gql`query Financier_quote_re_calculation {
    financier_quote_re_calculation(
        lead_id: "${params.lead_id}"
        financier_id: "${params.financier_id}"
        percent_age: "${params.percent_age}"
        amount: "${params.amount}"
        quote_id: "${params.quote_id}"
        interest_rate: "${params.interest_rate}"
        chattel_fee_age: "${params.chattel_fee_age}"
        is_selected: "${params.is_selected}"
    ) {
        financier_id
        financier_name
        max_percent_age
        min_percent_age
        max_fmv_in_car
        payment_list {
            quote_id
            is_selected
            amount_financed
            tenure
            interest_rate
            instalment_amount
            fmv_of_car
            chattel_fee
            chattel_fee_age
            carmudi_incentive_age
            carmudi_incentive_amount
            carmudi_top_up_age
            carmudi_top_up_amount
            total_carmudi_incentive_age
            carmudi_incentive_tax_age
            total_carmudi_incentive_net_of_tax
            interest_rate_list {
                id
                label
                value
            }
            chattel_fee_age_list {
                id
                label
                value
            }
        }
    }
}
`
};

export const SAVE_QUOTE_SELECTION = (data, lead_id) => {
  console.log("=========SAVE_QUOTE_SELECTION===========", data);
  // const params = JSON.stringify(data).replace(/"([^"]+)":/g, '$1:');
  // console.log("=========SAVE_QUOTE_SELECTION params===========", params);
  // const params = JSON.parse(data);
  // const jsonString = JSON.stringify(data, null, 2);
  return gql`mutation UpdateLeadDetails {
    updateLeadDetails(
        lead_data: {
            auto_save: 1
            tab_id: 3
            sub_tab_id: 3
            lead_id: ${lead_id}
            financier_quote_selection: ${data}
        }
    ) {
        lead_id
    }
}
`;
}

export const REQUEST_CSV_DOWNLOAD_UCRF = gql`
  mutation requestCsvDownload($applied_filters: String!, $user_id: Int!, $user_email: String!) {
    requestCsvDownload(params: {
      applied_filters: $applied_filters,
      user_id: $user_id,
      user_email: $user_email
    }) {
      message
    }
  }
`;

// export const SAVE_QUOTE_SELECTION = (financierQuoteSelection, leadId) => {
// return gql`
//   mutation UpdateLeadDetails($leadId: ${leadId}, $financierQuoteSelection: ${financierQuoteSelection}) {
//     updateLeadDetails(
//       lead_data: {
//         auto_save: 1
//         tab_id: 3
//         sub_tab_id: 3
//         lead_id: $leadId
//         financier_quote_selection: $financierQuoteSelection
//       }
//     ) {
//       lead_id
//     }
//   }
// `;
// }


export const QUOTE_SELECTION_PRE_FILLED = (lead_id) => {
  return gql`query GetFinancierPreFillData {
    getFinancierPreFillData(lead_id: ${lead_id}) {
        refinanceQuote {
            financier_id
            amount_financed
            mmv
            financier_name
            financier_logo
            fmv_of_car
            tenure
            instalment_amount
            interest_rate
            approval
            chattel_fee
            chattel_fee_age
            carmudi_incentive_age
            carmudi_incentive
            carmudi_incentive_tax_age
            carmudi_incentive_net_of_tax
            carmudi_top_up_age
            carmudi_top_up_amount
            total_carmudi_incentive_age
        }
        quote_financier_list {
            financier_id
            financier_name
            max_percent_age
            min_percent_age
            max_fmv_in_car
            percent_age
            financier_amount
            financier_variable_select
            payment_list {
                quote_id
                is_selected
                amount_financed
                tenure
                interest_rate
                instalment_amount
                fmv_of_car
                chattel_fee
                chattel_fee_age
                carmudi_incentive_age
                carmudi_incentive_amount
                carmudi_top_up_age
                carmudi_top_up_amount
                total_carmudi_incentive_age
                carmudi_incentive_tax_age
                total_carmudi_incentive_net_of_tax
                interest_rate_list {
                    id
                    label
                    value
                }
                chattel_fee_age_list {
                    id
                    label
                    value
                }
            }
        }
    }
}
`
}

export const LEADS_INCENTIVE_REMARK_QUERY_REFINANCE = (lead_id) => gql`
query{
  getIncentiveRemarksList(lead_id:${lead_id}){
    remark
    created_date
    created_by_name
    lead_id
    id
  }
}
`
export const GET_EXPORT_FIELDS = (lead_id) => {
  return gql`query ExportFieldList {
    getExportFieldList{
        id
        label
        value
    }
  }`;
};
