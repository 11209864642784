import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useApolloClient } from "@apollo/client";
import {
  GET_LOAN_EXPECTED_QUOTE_FINANCIER_LIST,
  FINANCIER_QUOTE_RE_CALCULATION,
  QUOTE_SELECTION_PRE_FILLED,
} from "../../../services/refinance/leads.gql";
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from "../../../services/customer.gql";
import {
  executeGraphQLQuery,
  executeGraphQLMutation,
} from "../../../services/refinance/executeGraphQLQuery";
import Select from "react-select";
import {
  getInterestAndChattelAge,
  mergeData,
  getInterestAndChattelAge2,
  prepareSaveQuoteData,
  updateSelectionRadio,
  selectFirstPaymentList,
  prepareSaveQuoteData2,
  updateFirstPaymentListQuote1,
  getIsSelected,
  transformDataAfterSave
} from "./utils";
import { toast } from "react-toastify";
// import { DP_VALUE } from "../../../config/constants";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";
import { useDispatch } from "react-redux";
import { getUCRFLeadDetail } from "../../../store/action/allAction";

const LoanQuoteSelection = (props) => {
  const client = useApolloClient();
  const dispatch = useDispatch();

  const [quotesArr, setQuotesArr] = useState([]);
  const [selectedFinanciers, setSelectedFinanciers] = useState([]);
  const [activeFinancier, setActiveFinancier] = useState("finance-0");
  const [activeFinancierQuote, setActiveFinancierQuote] = useState([]);
  const [activeFinancierId, setActiveFinancierId] = useState(0);
  const [activeFMV, setActiveFMV] = useState(0);
  const [required_percentage, setRquiredPercentage] = useState("");
  const [required_amt, setRequiredAmt] = useState("");
  const [enter_amount_to_financed, set_enter_amount_to_financed] =
    useState(false);

  //Enter Amount to be Financed radioButton name
  const [financier_variable_select, set_financier_variable_select] =
    useState("0");

  const [current_interest_rate, set_current_interest_rate] = useState(0);
  const [current_chattel_rate, set_current_chattel_rate] = useState(0);

  const onTabChange = async (tab, event) => {
    if (event.type === "click") {
      setActiveFinancier(tab);
      const quote = quotesArr?.filter(
        (quote) =>
          quote.financier_id ===
          Number(event?.target?.attributes?.financeid?.value)
      );
      setActiveFinancierQuote(quote);
      setActiveFinancierId(quote[0]?.financier_id || 0);
      setActiveFMV(quote[0]?.max_fmv_in_car || 0);
      // console.log("=======current debug quote[0]=====",quote[0]);
      let result = getInterestAndChattelAge2(
        quotesArr,
        quote[0]?.financier_id,
        0
      );
      set_current_chattel_rate(result.chattel_fee_age);
      set_current_interest_rate(result.interest_rate);
      // setRquiredPercentage("");
      // setRequiredAmt("");
      // set_enter_amount_to_financed(false)
    }
  };

  const handleDpOptionsChange = (event, type, financier_id, value = "") => {
    set_financier_variable_select(event?.target?.attributes?.id?.value || "0");
  };

  const handleQuote2Change = (event, type, financier_id, value = "") => {
    if (type === "required_percentage") {
      setRquiredPercentage(event.target.value);
      setRequiredAmt("");
    }
    if (type === "required_amt") {
      setRequiredAmt(event.target.value);
      setRquiredPercentage("");
    }
  };

  const calculateLoan = async () => {
    // const selectedFinancedType = financier_variable_select;
    let is_calculate = true;
    let error_msg = "";
    const lead_id = Number(props.lead_id);
    const financier_id = activeFinancierId;
    let quote2_percent_age =
      required_percentage !== "" ? required_percentage : "";
    let quote2_amount = required_amt !== "" ? required_amt : "";
    const quote_id = "2";
    let result = getInterestAndChattelAge(quotesArr, financier_id);
    const params = {
      lead_id,
      financier_id,
      percent_age: quote2_percent_age,
      amount: quote2_amount,
      quote_id,
      interest_rate: result.interest_rate,
      chattel_fee_age: result.chattel_fee_age,
      is_selected: 0,
    };

    if (params.percent_age !== "") {
      if (
        Number(params.percent_age) <= 100 &&
        Number(params.percent_age) >= 20
      ) {
        is_calculate = true;
      } else {
        is_calculate = false;
        error_msg = "percentage should be between 20 to 100";
      }
    }

    if (params.amount !== "") {
      if (Number(params.amount) <= Number(activeFMV)) {
        is_calculate = true;
      } else {
        is_calculate = false;
        error_msg = "amount should not larger than FMV";
      }
    }

    try {
      if (is_calculate) {
        const re_calculation = await executeGraphQLQuery(
          FINANCIER_QUOTE_RE_CALCULATION(params),
          client
        );
        // console.log("====re_calculation====", re_calculation);
        // re_calculation.data.financier_quote_re_calculation.forEach(newFinancier => {
        //   const financier = quotesArr.find(f => f.financier_id === newFinancier.financier_id);
        //   if (financier) {
        //       financier.payment_list = financier.payment_list.concat(newFinancier.payment_list);
        //   }
        // });
        const transformdata = mergeData(quotesArr, re_calculation);
        setQuotesArr(transformdata);
        const quote = transformdata?.filter(
          (quote) => quote.financier_id === Number(financier_id)
        );
        setActiveFinancierQuote(quote);
        // setRquiredPercentage("");
        // setRequiredAmt("");
        // set_enter_amount_to_financed(false)
      } else {
        toast.error(error_msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditOptionsChange = async (event, type, index) => {
    // alert(current_interest_rate);
    // alert(current_chattel_rate);

    // console.log(
    //   "debug handleEditOptionsChange activeFinancierQuote",
    //   activeFinancierQuote
    // );
    const lead_id = props.lead_id;
    const financier_id = activeFinancierId;
    let quote2_percent_age =
      required_percentage !== "" ? required_percentage : "";
    let quote2_amount = required_amt !== "" ? required_amt : "";
    const quote_id = index + 1;
    let interest_rate = current_interest_rate;
    let chattel_fee_age = current_chattel_rate;

    if (type === "interest_rate") {
      let result = getInterestAndChattelAge2(quotesArr, financier_id, index);
      // console.log("debug result", result);
      interest_rate = event.value;
      chattel_fee_age = result.chattel_fee_age;
      // set_current_interest_rate(interest_rate);
      // chattel_fee_age = result.chattel_fee_age;
    } else {
      // interest_rate = result.interest_rate;
      let result = getInterestAndChattelAge2(quotesArr, financier_id, index);
      chattel_fee_age = event.value;
      interest_rate = result.interest_rate;
      // set_current_chattel_rate(chattel_fee_age);
    }

    const getIsSelectedVal = getIsSelected(
      quotesArr,
      financier_id,
      quote_id.toString()
    );
    // console.log("====getIsSelectedVal====", getIsSelectedVal);

    const params = {
      lead_id,
      financier_id,
      percent_age: quote_id == 2 ? quote2_percent_age : "",
      amount: quote_id == 2 ? quote2_amount : "",
      quote_id,
      interest_rate,
      chattel_fee_age,
      is_selected: getIsSelectedVal ?? 0,
    };

    // console.log("=====params=====", params);

    const re_calculation = await executeGraphQLQuery(
      FINANCIER_QUOTE_RE_CALCULATION(params),
      client
    );

    // console.log("=====transformdata re_calculation===", re_calculation);
    const transformdata = mergeData(quotesArr, re_calculation);
    // console.log("=====transformdata===", transformdata);
    setQuotesArr(transformdata);
    const quote = transformdata?.filter(
      (quote) => quote.financier_id === Number(financier_id)
    );
    setActiveFinancierQuote(quote);
  };

  const createLeadQuote = async () => {
    const financier_id = activeFinancierId;
    // const transformData = prepareSaveQuoteData(quotesArr);
    let transformData = prepareSaveQuoteData2(quotesArr);
    // console.log("====transformData first===", transformData);
    transformData = updateFirstPaymentListQuote1(transformData);
    // console.log("====transformData second===", transformData);
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS;
    let variables = {
      lead_data: {
        created_by: 0,
        updated_by: 0,
        tab_id: 3,
        sub_tab_id: 3,
        lead_id: Number(props.lead_id),
        auto_save: 0,
        financier_quote_selection: transformData,
      },
    };

    executeGraphQLMutation(mutation, variables, client)
      .then((response) => {
        if (response?.data) {
          let resp = response?.data?.updateLeadDetails || null;
          if (resp && !resp.lead_id) {
            throw new Error(resp.error || "Something went wrong");
          }
          toast.success("Success");
          dispatch(getUCRFLeadDetail(props.lead_id, client));
          // dispatch(getUCRFFormFillStatus({ lead_id }, client));
          const savedData = transformDataAfterSave(transformData);
          setQuotesArr(savedData);
          const quote = savedData?.filter(
            (quote) => quote.financier_id === Number(financier_id)
          );
          setActiveFinancierQuote(quote);
          // console.log("============debug2 selection save======", variables);
          props.editQuote2();

        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  const handleRadioChange = (index) => {
    const financier_id = activeFinancierId;
    const transformdata = updateSelectionRadio(quotesArr, financier_id, index);
    setQuotesArr(transformdata);
    const quote = transformdata?.filter(
      (quote) => quote.financier_id === Number(financier_id)
    );
    setActiveFinancierQuote(quote);
  };

  useEffect(() => {
    const fetchFinancierQuoteList = async () => {
      if (props.lead_id) {
        try {
          let quotes = [];
          // if (!props.fetchNewQuote) {
          //   const pre_field_result = await executeGraphQLQuery(
          //     QUOTE_SELECTION_PRE_FILLED(props.lead_id),
          //     client
          //   );
          //   quotes =
          //     pre_field_result?.data?.getFinancierPreFillData
          //       ?.quote_financier_list || [];
          // }
          if (!quotes.length) {
            const result = await executeGraphQLQuery(
              GET_LOAN_EXPECTED_QUOTE_FINANCIER_LIST(props.lead_id),
              client
            );
            quotes = result?.data?.quote_financier_list || [];
          }
          quotes = selectFirstPaymentList(quotes);
          setQuotesArr(quotes);
          setSelectedFinanciers(quotes.map((ele) => ele.financier_id));
          //   setActiveFinancierQuote(quotes?.[0]);
          // Ensure the active quote is set for the first financier
          if (quotes.length > 0) {
            setActiveFinancier("finance-0"); // Set the active financier tab
            setActiveFinancierQuote([quotes[0]]); // Set the quote for the first financier
            setActiveFinancierId(quotes[0]?.financier_id);
            setActiveFMV(quotes[0]?.max_fmv_in_car || 0);
          }
        } catch (error) {
          console.error("Error fetching financier list", error);
        }
      }
    };

    fetchFinancierQuoteList();
  }, [props.lead_id]);

  useEffect(() => {
    const fetchFinancierQuoteList2 = async () => {
      if (props.lead_id && props.activeTab === "third") {
        try {
          let quotes = [];
          // if (!props.fetchNewQuote) {
          //   const pre_field_result = await executeGraphQLQuery(
          //     QUOTE_SELECTION_PRE_FILLED(props.lead_id),
          //     client
          //   );
          //   quotes =
          //     pre_field_result?.data?.getFinancierPreFillData
          //       ?.quote_financier_list || [];
          // }
          if (!quotes.length) {
            const result = await executeGraphQLQuery(
              GET_LOAN_EXPECTED_QUOTE_FINANCIER_LIST(props.lead_id),
              client
            );
            quotes = result?.data?.quote_financier_list || [];
          }
          // console.log("=======quotes=====", quotes);
          quotes = selectFirstPaymentList(quotes);
          setQuotesArr(quotes);
          setSelectedFinanciers(quotes.map((ele) => ele.financier_id));
          // console.log("======quotes[0]======", quotes[0]);
          //   setActiveFinancierQuote(quotes?.[0]);
          // Ensure the active quote is set for the first financier
          if (quotes.length > 0) {
            setActiveFinancier("finance-0"); // Set the active financier tab
            setActiveFinancierQuote([quotes[0]]); // Set the quote for the first financier
            setActiveFinancierId(quotes[0]?.financier_id);
            setActiveFMV(quotes[0]?.max_fmv_in_car || 0);
          }
        } catch (error) {
          console.error("Error fetching financier list", error);
        }
      }
    };

    fetchFinancierQuoteList2();
  }, [props.activeTab, props.fetchNewQuote]);

  // console.log("============debug2 selection default quotesArr=====", quotesArr);
  // console.log("============debug2 selection default quotesArr=====", quotesArr);

  return (
    <>
      <div className="">
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeFinancier}
          onSelect={(tab, e) => onTabChange(tab, e)}
          defaultActiveKey="finance-0"
        >
          <Nav variant="pills" className="flex-column financier-pills">
            <div className="tab-list">
              {selectedFinanciers?.length
                ? selectedFinanciers?.map((ele, key) => (
                  <Nav.Item>
                    <Nav.Link
                      eventKey={"finance-" + key}
                      financeId={parseInt(ele)}
                    >
                      {quotesArr?.find(
                        (quotes) => quotes.financier_id === ele
                      )?.financier_name || ""}
                    </Nav.Link>
                  </Nav.Item>
                ))
                : null}
            </div>
          </Nav>
        </Tab.Container>
      </div>
      <div className="financer-quote-outer qutote-section">
        {activeFinancierQuote?.length ? (
          activeFinancierQuote[0]?.payment_list?.map((payment_list, index) => (
            <div className="financer-quote-card">
              <div className="car-finance-detail">
                <div
                  className="quote-selectioin-checkbox form-filed radio-btn radio-type-txt"
                // style={{
                //   display:
                //     activeFinancierQuote?.length > 1 ? "block" : "none",
                // }}
                >
                  <div className="custom-control custom-checkbox">
                    <input
                      id={index}
                      type="radio"
                      onChange={(e) => handleRadioChange(index)}
                      name={"summary_radio"}
                      className="custom-control-input"
                      checked={payment_list?.is_selected === 1 ? true : false}
                      aria-label="handle change radio"
                    />{" "}
                    <label className="custom-control-label"></label>
                  </div>
                </div>
                <ul>
                  <>
                    <li>
                      <span>Loan Amount</span>
                      <label>Rp {payment_list?.amount_financed}</label>
                    </li>
                    <li>
                      <span>Tenure</span>
                      <label>{payment_list?.tenure}</label>
                    </li>
                    <li>
                      <span>Interest Rate</span>
                      <label>
                        <div className="single-select quote-select">
                          <Select
                            options={
                              payment_list?.interest_rate_list?.map((rate) => ({
                                label: `${rate.value}%`,
                                value: rate.value,
                              })) || []
                            }
                            placeholder={""}
                            className="quote-single-select"
                            classNamePrefix={"quote-single-select"}
                            name={"interest_rate"}
                            optionLabel="label"
                            optionValue="value"
                            value={{
                              label: String(payment_list?.interest_rate) + "%",
                              value: payment_list?.interest_rate,
                            }}
                            onChange={(e) =>
                              handleEditOptionsChange(e, "interest_rate", index)
                            }
                            aria-label="search type"
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <span>Installment Amount</span>
                      <label>{payment_list?.instalment_amount}</label>
                    </li>
                    <li>
                      <span>Chattel Fee</span>
                      <label>{payment_list?.chattel_fee}</label>
                    </li>
                    <li>
                      <span>Chattel Fee %age</span>
                      {/* <label>{payment_list?.chattel_fee_age}</label> */}
                      <label>
                        <div className="single-select quote-select">
                          <Select
                            options={
                              payment_list?.chattel_fee_age_list?.map(
                                (rate) => ({
                                  label: `${rate.value}%`,
                                  value: rate.value,
                                })
                              ) || []
                            }
                            placeholder={""}
                            className="quote-single-select"
                            classNamePrefix={"quote-single-select"}
                            name={"chattel_fee_age"}
                            optionLabel="label"
                            optionValue="value"
                            value={{
                              label:
                                String(payment_list?.chattel_fee_age) + "%",
                              value: payment_list?.chattel_fee_age,
                            }}
                            onChange={(e) =>
                              handleEditOptionsChange(
                                e,
                                "chattel_fee_age",
                                index
                              )
                            }
                            aria-label="search type"
                          />
                        </div>
                      </label>
                    </li>
                    <li>
                      <span>Carmudi Incentive %</span>
                      <label>{payment_list?.carmudi_incentive_age}</label>
                    </li>
                    <li>
                      <span>Carmudi Incentive</span>
                      <label>{payment_list?.carmudi_incentive_amount}</label>
                    </li>
                    <li>
                      <span>Incentive Tax %</span>
                      <label>{payment_list?.carmudi_incentive_tax_age}</label>
                    </li>
                    <li>
                      <span>Incentive Tax</span>
                      <label>
                        {payment_list?.total_carmudi_incentive_net_of_tax}
                      </label>
                    </li>
                    <li>
                      <span>FMV of Car</span>
                      <label>{activeFinancierQuote[0]?.max_fmv_in_car}</label>
                    </li>
                  </>
                </ul>
              </div>
            </div>
          ))
        ) : (
          <span>No Quotes Available</span>
        )}
      </div>

      {activeFinancierQuote?.[0]?.financier_id ? (
        <div className="amount-finance-outer m-md-t m-md-b">
          <h3
            onClick={() => {
              set_enter_amount_to_financed(!enter_amount_to_financed);
            }}
          >
            Enter Amount to be Financed
          </h3>
          {enter_amount_to_financed && (
            <>
              <fieldset className="form-filed radio-btn radio-type-txt">
                <div className="custom-control custom-checkbox">
                  <input
                    id="0"
                    type="radio"
                    className="custom-control-input"
                    name="ongoing_promo"
                    aria-label="handle change radio"
                    checked={financier_variable_select === '0'}
                    // value={`per_` + activeFinancierQuote?.[0]?.financier_id}
                    // defaultChecked={
                    //   requiredDpOptions.find(
                    //     (options) =>
                    //       options.financier_id ===
                    //       activeFinancierQuote?.[0]?.financier_id
                    //   )?.option_selected ===
                    //   `per_` + activeFinancierQuote?.[0]?.financier_id
                    // }
                    onClick={(e) =>
                      handleDpOptionsChange(
                        e,
                        "option_selected",
                        activeFinancierQuote?.[0]?.financier_id,
                        "per_" + activeFinancierQuote?.[0]?.financier_id
                      )
                    }
                  />
                  <label className="custom-control-label">Percentage (%)</label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    id="1"
                    type="radio"
                    className="custom-control-input"
                    name="ongoing_promo"
                    aria-label="handle change radio"
                    checked={financier_variable_select === '1'}                    
                    // value={`amount_` + activeFinancierQuote?.[0]?.financier_id}
                    // defaultChecked={
                    //   requiredDpOptions.find(
                    //     (options) =>
                    //       options.financier_id ===
                    //       activeFinancierQuote?.[0]?.financier_id
                    //   )?.option_selected ===
                    //   `amount_` + activeFinancierQuote?.[0]?.financier_id
                    // }
                    onClick={(e) =>
                      handleDpOptionsChange(
                        e,
                        "option_selected",
                        activeFinancierQuote?.[0]?.financier_id,
                        "amount_" + activeFinancierQuote?.[0]?.financier_id
                      )
                    }
                  />
                  <label className="custom-control-label">Amount</label>
                </div>
              </fieldset>

              <div className="dp-amt-btn">
                <fieldset class="single-select">
                  {financier_variable_select == "0" && (
                    <div className="material">
                      <div class="material">
                        <input
                          id={
                            `percent_` + activeFinancierQuote?.[0]?.financier_id
                          }
                          name={
                            `percent_` + activeFinancierQuote?.[0]?.financier_id
                          }
                          type="number"
                          placeholder=" "
                          label="Add Required DP%"
                          autocomplete="new-off"
                          class="form-input"
                          title=""
                          value={required_percentage}
                          onChange={(e) =>
                            handleQuote2Change(
                              e,
                              "required_percentage",
                              activeFinancierQuote?.[0]?.financier_id
                            )
                          }
                        />

                        <label
                          data-label="Add Required %"
                          class="form-label "
                        ></label>
                      </div>
                    </div>
                  )}
                  {financier_variable_select == "1" && (
                    <div class="material">
                      <input
                        id={`amount_` + activeFinancierQuote?.[0]?.financier_id}
                        name={
                          `amount_` + activeFinancierQuote?.[0]?.financier_id
                        }
                        type="number"
                        placeholder=" "
                        label="Add Required Amount"
                        autocomplete="new-off"
                        class="form-input"
                        title=""
                        value={required_amt}
                        onChange={(e) =>
                          handleQuote2Change(
                            e,
                            "required_amt",
                            activeFinancierQuote?.[0]?.financier_id
                          )
                        }
                      />

                      <label
                        data-label="Add Required Amount"
                        class="form-label "
                      ></label>
                    </div>
                  )}
                </fieldset>
                {!props.isSaveDisable &&
                  <button
                    className="btn-primary"
                    type="button"
                    onClick={calculateLoan}
                  >
                    Submit
                  </button>
                }
              </div>
            </>
          )}
          {/* {isMaxDpAmount ? (
            <div class="error-msg-txt">Amount must be less than FMV</div>
          ) : (
            ""
          )} */}
        </div>
      ) : null}

      {!props.isSaveDisable &&
        <>
          {activeFinancierQuote?.length ? (
            <div class="btn-save-remarks">
              <button
                type="button"
                class="btn-primary"
                // disabled={
                //   isMaxDpAmount ||
                //   !selectedFinanciers?.length ||
                //   !selectedQuotes?.length
                // }
                onClick={createLeadQuote}
              >
                Save & Continue
              </button>
            </div>
          ) : null}
        </>
      }
    </>
  );
};

export default LoanQuoteSelection;
// const areEqual = (prevProps, nextProps) => {
//   return prevProps.carDetails === nextProps.carDetails;
// };

// export default React.memo(LoanQuoteSelection, areEqual);
