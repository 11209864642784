import Formsy, { addValidationRule } from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import FormsyTextAreaAutosize from "../../elements/FormsyTextAreaAutosize";
import SimpleFileUpload from "./SimpleFileUpload";
import FormsyDatePicker from "../../elements/FormsyDatePicker";

addValidationRule("isPositiveInteger", (values, value) => {
    return value === "" || /^[1-9]\d*$/.test(value);
});

const MAX_DECIMAL = 14;
const now = new Date();

const FinancierLoanDetails = ({ financierId, financierLoanData, handleChange, handleSave, formRef, markAsFreezed }) => {
    return (
        <>
            <Formsy className="lead-form-filed soft-approval-filled-outer m-md-t" autoComplete="off" onSubmit={handleSave} ref={formRef}>
                <div className="row">
                    <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                        <label className="form-label">Loan Approved* ? </label>
                        <div className="custom-control custom-checkbox" key={"yes"}>
                            <input
                                id="yes"
                                aria-label={`for Yes`}
                                type="radio"
                                className="custom-control-input"
                                name={`loan_approved`}
                                value={"yes"}
                                checked={financierLoanData?.additional_loan_info?.loan_approved === "yes"}
                                onChange={(e) => handleChange(e, financierId, `additional_loan_info`, `loan_approved`, "radio")}
                                disabled={markAsFreezed || false}
                                required={true}
                            />
                            <label htmlFor={"loan_approved"} className="custom-control-label">
                                Yes
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox" key={"no"}>
                            <input
                                id="no"
                                aria-label={`for No`}
                                type="radio"
                                className="custom-control-input"
                                name={`loan_approved`}
                                value={"no"}
                                checked={financierLoanData?.additional_loan_info?.loan_approved === "no"}
                                onChange={(e) => handleChange(e, financierId, `additional_loan_info`, `loan_approved`, "radio")}
                                disabled={markAsFreezed || false}
                                required={true}
                            />
                            <label htmlFor={"loan_approved"} className="custom-control-label">
                                No
                            </label>
                        </div>
                    </fieldset>
                </div>
                {financierLoanData?.additional_loan_info?.loan_approved === "no" ? (
                    <div className="row">
                        <fieldset className="form-filed text-area-filed col-md-12">
                            <div className="material">
                                <FormsyTextAreaAutosize
                                    name={"report_status"}
                                    value={financierLoanData?.additional_loan_info?.report_status}
                                    placeholder=" "
                                    label={"Report Status"}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "additional_loan_info", "report_status", "textarea");
                                    }}
                                    format={""}
                                    required={true}
                                    readOnly={markAsFreezed || false}
                                    showAsterisk={true}
                                />
                                <label data-label={"Report Status"} className="form-label"></label>
                            </div>
                        </fieldset>
                    </div>
                ) : (
                    <>
                        <div className="row">
                            <fieldset className="form-filed col-md-12">
                                <SimpleFileUpload
                                    disabled={markAsFreezed}
                                    financierId={financierId}
                                    label={"Financier Loan Approval"}
                                    handleChange={handleChange}
                                    attachments={financierLoanData?.["additional_loan_info"]?.["financier_approval_docs"] || []}
                                />
                            </fieldset>
                        </div>

                        <Accordion allowZeroExpanded>
                            <div className="">
                                <AccordionItem className="acc-item-financer">
                                    <AccordionItemHeading className="acc-heading-financer">
                                        <AccordionItemButton>
                                            <div className="financer-name-date">
                                                <h3 title={"Loan Details"}>Loan Details</h3>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="acc-panel-finaner">
                                        <div className="soft-approvel-sec">
                                            <div className="row">
                                                <fieldset class="form-filed col-md-12 ">
                                                    <FormsyInputField
                                                        id="type_of_loan"
                                                        name="type_of_loan"
                                                        type="text"
                                                        value={financierLoanData?.loan_details?.type_of_loan}
                                                        placeholder=" "
                                                        label={"Type of Loan"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "type_of_loan", "input");
                                                        }}
                                                        validations={(rule, value) => {
                                                            return typeof value === "string";
                                                        }}
                                                        validationError="Enter Valid Loan Type"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-12 ">
                                                    <FormsyInputField
                                                        id="branch_name"
                                                        name="branch_name"
                                                        type="text"
                                                        value={financierLoanData?.loan_details?.branch_name}
                                                        placeholder=" "
                                                        label={"Branch Name"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "branch_name", "input");
                                                        }}
                                                        validations={(rule, value) => {
                                                            return typeof value === "string";
                                                        }}
                                                        validationError="Enter Valid Branch Name"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-12">
                                                    <FormsyDatePicker
                                                        col="col-md-12"
                                                        id="loan_approval_date"
                                                        name="loan_approval_date"
                                                        selectedDate={
                                                            financierLoanData?.loan_details?.loan_approval_date &&
                                                            new Date(financierLoanData?.loan_details?.loan_approval_date)
                                                        }
                                                        value={
                                                            financierLoanData?.loan_details?.loan_approval_date &&
                                                            new Date(financierLoanData?.loan_details?.loan_approval_date)
                                                        }
                                                        placeholder={"Loan Approval Date"}
                                                        maxDate={new Date()}
                                                        minTime={now.setHours(0, 0, 0)}
                                                        maxTime={Date.now()}
                                                        dateFormat="dd MMM, yyyy h:mm aa"
                                                        validations={(rule, value) => typeof value === "string"}
                                                        validationError="Enter Valid Date"
                                                        showTimeSelect={true}
                                                        required={true}
                                                        readOnly={markAsFreezed || false}
                                                        showAsterisk={true}
                                                        disabled={false}
                                                        hasError={""}
                                                        clearDate={false}
                                                        onDateSelect={(e) => {
                                                            handleChange(e, financierId, "loan_details", "loan_approval_date", "date");
                                                        }}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="loan_amount"
                                                        name="loan_amount"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.loan_details?.loan_amount}
                                                        placeholder=" "
                                                        label={"Loan Amount"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "loan_amount", "number-format");
                                                        }}
                                                        // validations={"ucrfMaxAmountLength"}
                                                        rule={"max16DigitValidation"}
                                                        validationError="Enter Valid Loan Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="interest_rate"
                                                        name="interest_rate"
                                                        type="number-format"
                                                        decimalSeparator="."
                                                        value={financierLoanData?.loan_details?.interest_rate}
                                                        placeholder=" "
                                                        label={"Intrest Rate %"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "interest_rate", "number-format");
                                                        }}
                                                        validations={"percentageValidation"}
                                                        rule={"max16DigitValidation"}
                                                        validationError="Enter Valid Branch Name"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="interest_amount"
                                                        name="interest_amount"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.loan_details?.interest_amount}
                                                        placeholder=" "
                                                        label={"Installment"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "interest_amount", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Installment Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="term_in_months"
                                                        name="term_in_months"
                                                        type="number-format"
                                                        value={financierLoanData?.loan_details?.term_in_months}
                                                        placeholder=" "
                                                        decimalScale={0}
                                                        label={"Tenure"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "term_in_months", "number-format");
                                                        }}
                                                        validations={"isPositiveInteger"}
                                                        validationError="Please enter a valid positive integer for the tenure"
                                                        readOnly={markAsFreezed || false}
                                                        required={true}
                                                        showAsterisk={true}
                                                    />
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 loan-detail-form-label">
                                                    <label>Total Loan Amount*</label>
                                                </fieldset>

                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="total_loan_amount"
                                                        name="total_loan_amount"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.loan_details?.total_loan_amount}
                                                        placeholder=" "
                                                        label={""}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "loan_details", "total_loan_amount", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Amount"
                                                        required={true}
                                                        readOnly={markAsFreezed || false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        </Accordion>

                        <Accordion allowZeroExpanded>
                            <div className="">
                                <AccordionItem className="acc-item-financer">
                                    <AccordionItemHeading className="acc-heading-financer">
                                        <AccordionItemButton>
                                            <div className="financer-name-date">
                                                <h3 title={"Standard Deductions"}>Standard Deductions</h3>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="acc-panel-finaner">
                                        <div className="soft-approvel-sec">
                                            <div className="row">
                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="processing_fee"
                                                        name="processing_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.processing_fee}
                                                        placeholder=" "
                                                        label={"Processing Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "processing_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Processing Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="chm_rem_fee"
                                                        name="chm_rem_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.chm_rem_fee}
                                                        placeholder=" "
                                                        label={"CHM REM Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "chm_rem_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid CHM REM Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="chm_tds_stamp"
                                                        name="chm_tds_stamp"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.chm_tds_stamp}
                                                        placeholder=" "
                                                        label={"CHM TDS Stamp"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "chm_tds_stamp", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid CHM TDS Stamp"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="pn_doc_stamp"
                                                        name="pn_doc_stamp"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.pn_doc_stamp}
                                                        placeholder=" "
                                                        label={"PN Doc Stamp"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "pn_doc_stamp", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid PN Doc Stamp"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="notarial_fee"
                                                        name="notarial_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.notarial_fee}
                                                        placeholder=" "
                                                        label={"Notarial Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "notarial_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Notarial Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="crf"
                                                        name="crf"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.crf}
                                                        placeholder=" "
                                                        label={"CRF"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "crf", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid CRF"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="service_fee"
                                                        name="service_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.service_fee}
                                                        placeholder=" "
                                                        label={"Service Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "service_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Service Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="transfer_of_ownership"
                                                        name="transfer_of_ownership"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.transfer_of_ownership}
                                                        placeholder=" "
                                                        label={"Transfer of Ownership"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "transfer_of_ownership", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Transfer of Ownership"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="bdo_on_site"
                                                        name="bdo_on_site"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.bdo_on_site}
                                                        placeholder=" "
                                                        label={"BDO On Site"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "bdo_on_site", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid BDO On Site"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="lri"
                                                        name="lri"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.lri}
                                                        placeholder=" "
                                                        label={"LRI"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "lri", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid LRI"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="registration_fee"
                                                        name="registration_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.registration_fee}
                                                        placeholder=" "
                                                        label={"Registration Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "registration_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Registration Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>
                                            </div>
                                            <div className="row">
                                                <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                                    <label>Total Standard Deductions</label>
                                                </fieldset>
                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="total_standard_deductions"
                                                        name="total_standard_deductions"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.standard_deductions?.total_standard_deductions}
                                                        placeholder=" "
                                                        label={""}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "standard_deductions", "total_standard_deductions", "number-format");
                                                        }}
                                                        // validations={"ucrfMaxAmountLength"}
                                                        rule={"max16DigitValidation"}
                                                        validationError="Enter Valid Total Additional Deductions"
                                                        readOnly={markAsFreezed || true}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        </Accordion>

                        <Accordion allowZeroExpanded>
                            <div className="">
                                <AccordionItem className="acc-item-financer">
                                    <AccordionItemHeading className="acc-heading-financer">
                                        <AccordionItemButton>
                                            <div className="financer-name-date">
                                                <h3 title={"Additional Deductions"}>Additional Deductions</h3>
                                            </div>
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel className="acc-panel-finaner">
                                        <div className="soft-approvel-sec">
                                            <div className="row">
                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="ltp"
                                                        name="ltp"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.ltp}
                                                        placeholder=" "
                                                        label={"LTP"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "ltp", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid LTP"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="cash_card"
                                                        name="cash_card"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.cash_card}
                                                        placeholder=" "
                                                        label={"Cash Card"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "cash_card", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Cash Card Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="direct_deposit"
                                                        name="direct_deposit"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.direct_deposit}
                                                        placeholder=" "
                                                        label={"Direct Deposit"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "direct_deposit", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Direct Deposit Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="proceed_charge"
                                                        name="proceed_charge"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.proceed_charge}
                                                        placeholder=" "
                                                        label={"Proceed Charge"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "proceed_charge", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Proceed Charge"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="comprehensive_ins"
                                                        name="comprehensive_ins"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.comprehensive_ins}
                                                        placeholder=" "
                                                        label={" Comprehensive  Insurance"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "comprehensive_ins", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Comprehensive Insurance Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="handling_fee"
                                                        name="handling_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.handling_fee}
                                                        placeholder=" "
                                                        label={"Handling Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "handling_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Handling Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="pull_out_fee"
                                                        name="pull_out_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.pull_out_fee}
                                                        placeholder=" "
                                                        label={"Pull Out Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "pull_out_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Pull Out Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="extra_service"
                                                        name="extra_service"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.extra_service}
                                                        placeholder=" "
                                                        label={"Extra Service"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "extra_service", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Extra Service Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="cancellation_fee"
                                                        name="cancellation_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.cancellation_fee}
                                                        placeholder=" "
                                                        label={"Cancellation Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "cancellation_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Cancellation Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="appraisal_fee"
                                                        name="appraisal_fee"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.appraisal_fee}
                                                        placeholder=" "
                                                        label={"Appraisal Fee"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "appraisal_fee", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Appraisal Fee"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>

                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="open_ca"
                                                        name="open_ca"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.open_ca}
                                                        placeholder=" "
                                                        label={"Open CA"}
                                                        onChange={(e) => {
                                                            handleChange(e, financierId, "additional_deductions", "open_ca", "number-format");
                                                        }}
                                                        validations={"ucrfMaxAmountLength"}
                                                        validationError="Enter Valid Open CA Amount"
                                                        readOnly={markAsFreezed || false}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>
                                            </div>
                                            <div className="row">
                                                <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                                    <label>Total Additional Deductions</label>
                                                </fieldset>
                                                <fieldset className="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id="total_additional_deductions"
                                                        name="total_additional_deductions"
                                                        type="number-format"
                                                        thousandsGroupStyle="thousand"
                                                        thousandSeparator=","
                                                        decimalSeparator="."
                                                        decimalScale={MAX_DECIMAL}
                                                        value={financierLoanData?.additional_deductions?.total_additional_deductions}
                                                        placeholder=" "
                                                        label={"Total Additional Deductions"}
                                                        onChange={(e) => {
                                                            handleChange(
                                                                e,
                                                                financierId,
                                                                "additional_deductions",
                                                                "total_additional_deductions",
                                                                "number-format"
                                                            );
                                                        }}
                                                        // validations={"ucrfMaxAmountLength"}
                                                        rule={"max16DigitValidation"}
                                                        validationError="Enter Valid Total Additional Deductions"
                                                        readOnly={true}
                                                        required={false}
                                                        showAsterisk={false}
                                                    />
                                                </fieldset>
                                            </div>
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </div>
                        </Accordion>

                        <div className="row">
                            <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                <label>Total Deductions</label>
                            </fieldset>
                            <fieldset className="form-filed col-md-6">
                                <FormsyInputField
                                    id="total_deductions"
                                    name="total_deductions"
                                    type="number-format"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={MAX_DECIMAL}
                                    value={financierLoanData?.loan_calculations?.total_deductions}
                                    placeholder=" "
                                    label={""}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "loan_calculations", "total_deductions", "number-format");
                                    }}
                                    // validations={"ucrfMaxAmountLength"}
                                    rule={"max16DigitValidation"}
                                    validationError="Enter Valid Total Deductions"
                                    readOnly={true}
                                    required={false}
                                    showAsterisk={false}
                                />
                            </fieldset>
                        </div>

                        <div className="row">
                            <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                <label>Rebate</label>
                            </fieldset>
                            <fieldset className="form-filed col-md-6">
                                <FormsyInputField
                                    id="rebate"
                                    name="rebate"
                                    type="number-format"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={MAX_DECIMAL}
                                    value={financierLoanData?.loan_calculations?.rebate}
                                    placeholder=" "
                                    label={""}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "loan_calculations", "rebate", "number-format");
                                    }}
                                    validations={"ucrfMaxAmountLength"}
                                    validationError="Enter Valid Rebate"
                                    readOnly={markAsFreezed || false}
                                    required={false}
                                    showAsterisk={false}
                                />
                            </fieldset>
                        </div>

                        <div className="row">
                            <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                <label>Net Proceeds*</label>
                            </fieldset>
                            <fieldset className="form-filed col-md-6">
                                <FormsyInputField
                                    id="net_proceeds"
                                    name="net_proceeds"
                                    type="number-format"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={MAX_DECIMAL}
                                    value={financierLoanData?.loan_calculations?.net_proceeds > 0 ? financierLoanData?.loan_calculations?.net_proceeds : 0}
                                    placeholder=" "
                                    label={""}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "loan_calculations", "net_proceeds", "number-format");
                                    }}
                                    // validations={"ucrfMaxAmountLength"}
                                    rule={"max16DigitValidation"}
                                    validationError="Enter Valid Net Proceeds"
                                    readOnly={true}
                                    required={true}
                                    showAsterisk={false}
                                />
                            </fieldset>
                        </div>

                        <div className="row">
                            <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                <label>Monthly Amortization</label>
                            </fieldset>
                            <fieldset className="form-filed col-md-6">
                                <FormsyInputField
                                    id="monthly_amortization"
                                    name="monthly_amortization"
                                    type="number-format"
                                    thousandsGroupStyle="thousand"
                                    thousandSeparator=","
                                    decimalSeparator="."
                                    decimalScale={MAX_DECIMAL}
                                    value={financierLoanData?.loan_calculations?.monthly_amortization}
                                    placeholder=" "
                                    label={""}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "loan_calculations", "monthly_amortization", "number-format");
                                    }}
                                    validations={"ucrfMaxAmountLength"}
                                    validationError="Enter Valid Monthly Amortization"
                                    readOnly={markAsFreezed || false}
                                    required={false}
                                    showAsterisk={false}
                                />
                            </fieldset>
                        </div>

                        <div className="row">
                            <fieldset className="form-filed col-md-6 loan-detail-form-label">
                                <label>PF Rate</label>
                            </fieldset>
                            <fieldset className="form-filed col-md-6">
                                <FormsyInputField
                                    id="pf_rate"
                                    name="pf_rate"
                                    type="number-format"
                                    decimalSeparator="."
                                    value={financierLoanData?.loan_calculations?.pf_rate}
                                    placeholder=" "
                                    label={""}
                                    onChange={(e) => {
                                        handleChange(e, financierId, "loan_calculations", "pf_rate", "number-format");
                                    }}
                                    validations={"percentageValidation"}
                                    validationError="Enter Valid PF Rate"
                                    readOnly={markAsFreezed || false}
                                />
                            </fieldset>
                        </div>
                    </>
                )}
            </Formsy>
        </>
    );
};

export default FinancierLoanDetails;
