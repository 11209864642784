import { useEffect, useMemo, useRef, useState } from "react";
import Loader from "../../elements/Loader";
import Formsy, { addValidationRule } from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getUCRFFormFillStatus, getUCRFLeadDetail, getLeadRemarkUCRF } from "../../../store/action/allAction";
import { executeGraphQLMutation } from "../../../services/refinance/executeGraphQLQuery";
import { SAVE_REFINANCE_CALCULATION } from "../../../services/refinance/refinance-computation.gql";
import DocumentGallery from "../DocsUpload/DocumentGallery";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from "../../../config/constants";
import LeadRemarkHistory from "../lead/component/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { COMPUTATION_GENERATED, LOST_STATUS } from "../../../config/refinance/constant";

/**
 * Recursively removes '__typename' key from all objects and arrays
 * @param {*} data - The input data (object, array, or primitive)
 * @returns {*} - The data with all '__typename' keys removed
 */
const removeTypename = (data) => {
    if (Array.isArray(data)) {
        return data.map((item) => removeTypename(item));
    } else if (data !== null && typeof data === "object") {
        const newObj = {};
        for (const [key, value] of Object.entries(data)) {
            if (key !== "__typename") {
                newObj[key] = removeTypename(value);
            }
        }
        return newObj;
    }
    return data;
};

const MAX_DECIMAL = 14;
// const roundLargeNumber = (num) => {
//     if (typeof num !== "number") {
//         return num;
//     }

//     const strNum = num.toString();
//     const [intPart, decPart] = strNum.split(".");

//     if (strNum?.length > 16) {
//         if (decPart?.length) {
//             return strNum.substring(0, 16);
//         } else if (!decPart || strNum.length <= 16) {
//             return num;
//         }
//     }
//     const decimalPlaces = 16 - intPart.length - 1;
//     return parseFloat(num.toFixed(decimalPlaces));
// };

function roundLargeNumber(num, totalDigits = 16) {
    if (typeof num !== "number") {
        return num;
    }

    // Convert to string to manipulate easily
    let numStr = num.toString();
    
    // Find the decimal point
    const decimalIndex = numStr.indexOf('.');
    
    // Get the part before the decimal and the fractional part
    let integerPart = numStr.slice(0, decimalIndex === -1 ? undefined : decimalIndex);
    let fractionalPart = decimalIndex === -1 ? "" : numStr.slice(decimalIndex + 1);

    // If integer part is already 16 digits or more, truncate and return
    if (integerPart.length >= totalDigits) {
        return parseFloat(integerPart.slice(0, totalDigits));
    }

    // Calculate how many fractional digits we can keep
    const maxFractionalDigits = totalDigits - integerPart.length;

    // Trim trailing zeros from fractional part
    let lastSignificantIndex = fractionalPart.length - 1;
    while (lastSignificantIndex >= 0 && fractionalPart[lastSignificantIndex] === '0') {
        lastSignificantIndex--;
    }
    fractionalPart = fractionalPart.slice(0, lastSignificantIndex + 1);

    // If fractional part is longer than allowed, truncate it
    if (fractionalPart.length > maxFractionalDigits) {
        fractionalPart = fractionalPart.slice(0, maxFractionalDigits);
    }

    // Combine the parts and convert back to a number
    return parseFloat(`${integerPart}${fractionalPart ? '.' + fractionalPart : ''}`);
}

const RefinanceComputationGenerated = (props) => {
    let { lead_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const client = useApolloClient();
    const { remarksHistory, leadDetail } = useSelector(({ lead, masterdataucrf }) => ({
        master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
        lead,
        remarksHistory: lead.remarksHistory || [],
        leadDetail: lead?.ucrfLeadDetail || {},
    }));
    const formRef = useRef();
    const [loading, setLoading] = useState(false);
    const [computationData, setComputationData] = useState({});
    const [basicDetails, setBasicDetails] = useState({});

    const getContext = useOutletContext();
    const documentCategoryList = getContext.docList || [];
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const [showremarkform, setShowremarkform] = useState(false);
    const [isSaveDisable, setIsSaveDisabled] = useState(true);
    const [isRemarkDisable, setIsRemarkDisabled] = useState(true);
    const [isNextEnable, setIsNextEnabled] = useState(false);
    const [isDataFilled, setIsDataFilled] = useState(false);
    let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;
    if (!accessCondition) {
        accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
    }

    useEffect(() => {
        if (leadDetail?.computation_details) {
            const copiedData = JSON.parse(JSON.stringify(leadDetail?.computation_details));
            const initializedData = calculateValues(removeTypename(copiedData));
            setIsDataFilled(initializedData.data_filled === 1);
            setComputationData(initializedData);
        }
        checkSaveEnable(leadDetail);
        let basicDetails = { ...leadDetail.basic_details };
        setBasicDetails(basicDetails);
        dispatch(getLeadRemarkUCRF({ lead_id: leadDetail.lead_id, source: "WEB" }, client));
    }, [leadDetail]);

    const navigateToNext = () => {
        navigate(`/refinance/lead-detail/payment-verification/${lead_id}`);
    };

    const calculateValues = (computationData) => {
        const loanAmount = parseFloat(computationData.loan_amount) || 0;
        let updatedData = { ...computationData };

        // Pass Through calculations
        if (updatedData.pass_through && leadDetail?.basic_details?.user_onboarded == 0) {
            const payoutPercentage = parseFloat(updatedData.pass_through.payout_percentage) || 0;
            const taxPercentage = parseFloat(updatedData.pass_through.tax_percentage) || 0;

            // Direct Dealer Payout from Financier Amount Net of Tax
            // Formula: (Payout Percentage / 100 * Loan Amount) * (1 - Tax Percentage / 100)
            const payoutAmount = (payoutPercentage / 100) * loanAmount;
            updatedData.pass_through.payout_amount = roundLargeNumber(payoutAmount * (1 - taxPercentage / 100));
        }

        // Incentive Amount From Financier calculations
        if (updatedData.incentive_amount_from_financier && leadDetail?.basic_details?.user_onboarded == 0) {
            const diPercentage = parseFloat(updatedData.incentive_amount_from_financier.di_percentage) || 0;
            const diTaxPercentage = parseFloat(updatedData.incentive_amount_from_financier.di_tax_percentage) || 0;

            // DI Amount from Financier to Carmudi Amount
            // Formula: DI Percentage / 100 * Loan Amount
            updatedData.incentive_amount_from_financier.di_amount = roundLargeNumber((diPercentage / 100) * loanAmount);

            // DI Amount from Financier to Carmudi Net of Tax
            // Formula: DI Amount * (1 - DI Tax Percentage / 100)
            const diAmount = parseFloat(updatedData.incentive_amount_from_financier.di_amount);
            updatedData.incentive_amount_from_financier.di_tax_amount = roundLargeNumber(diAmount * (1 - diTaxPercentage / 100));
        }

        // Computation Details calculations
        if (updatedData.computation_details && leadDetail?.basic_details?.user_onboarded == 0) {
            const incentivePct = parseFloat(updatedData.computation_details.incentive_pct) || 0;
            const topupPct = parseFloat(updatedData.computation_details.topup_pct) || 0;
            const incentiveTaxPct = parseFloat(updatedData.computation_details.incentive_tax_pct) || 10; // Default to 10% if not set

            // Carmudi's Incentive %age Net of Tax
            // Formula: Carmudi Incentive %age (Gross) * 0.9
            // updatedData.computation_details.incentive_net_tax = incentivePct * 0.9;

            // Carmudi Incentive Amount
            // Formula: Carmudi Incentive %age (Gross) / 100 * Loan Value
            updatedData.computation_details.incentive_amount = roundLargeNumber((incentivePct / 100) * loanAmount);

            // Carmudi Top-up Amount
            // Formula: Carmudi Top-up % / 100 * Loan Value
            updatedData.computation_details.topup_amount = roundLargeNumber((topupPct / 100) * loanAmount);

            // Total Carmudi Incentive %age
            // Formula: Carmudi Top-up % + Carmudi Incentive %age (Gross)
            updatedData.computation_details.total_incentive_pct = incentivePct + topupPct;

            // Total Carmudi Incentive Amount
            // Formula: Carmudi Top Up Amount + Carmudi Incentive Amount
            const totalIncentiveAmount =
                parseFloat(updatedData.computation_details.incentive_amount) + parseFloat(updatedData.computation_details.topup_amount);
            updatedData.computation_details.total_incentive_amount = roundLargeNumber(totalIncentiveAmount);

            // Total Carmudi Incentive Amount Net of Tax
            // Formula: Total Carmudi Incentive Amount * (1 - Carmudi Incentive Tax %age / 100)
            updatedData.computation_details.total_incentive_amount_net_of_tax = roundLargeNumber(totalIncentiveAmount * (1 - incentiveTaxPct / 100));

            // Carmudi's Incentive %age Net of Tax
            // Formula: Total Carmudi Incentive Amount * (1 - Carmudi Incentive Tax %age)
            updatedData.computation_details.incentive_net_tax = roundLargeNumber(totalIncentiveAmount * (1 - incentiveTaxPct / 100));
        }

        return updatedData;
    };

    const submitForm = () => {
        if (formRef.current) {
            formRef.current.validateForm();
            if (formRef.current.state.isValid) {
                formRef.current.submit();
            } else {
                toast.error("Please correct the errors in the form");
            }
        }
    };

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const handleChange = (event, parentField, childField, fieldType) => {
        let tempComputationDetails = JSON.parse(JSON.stringify(computationData));

        if (!tempComputationDetails[parentField]) {
            tempComputationDetails[parentField] = {};
        }
        let newValue;
        if (fieldType === "number-format") {
            newValue = event.value;
        } else if (fieldType === "text") {
            newValue = getMax16DigitValidation("percent", event.target.value);
        } else if (["radio", "input"].includes(fieldType)) {
            newValue = event.target.value;
        }

        if (newValue) {
            if (childField === "dealer_incentive_amount") {
                tempComputationDetails["pass_through"]["payout_percentage"] = "";
                tempComputationDetails["pass_through"]["tax_percentage"] = "";
                tempComputationDetails["pass_through"]["payout_amount"] = "";
            }
            tempComputationDetails[parentField][childField] = newValue;
            const updatedData = calculateValues(tempComputationDetails);
            setComputationData(updatedData);
        }
    };

    const getMax16DigitValidation = (type, value) => {
        if (type == "percent") {
            let validated = value > 100 ? false : true;
            let valueToUpdate = value;
            if (validated) {
                if (valueToUpdate == "" || (valueToUpdate >= 0 && valueToUpdate <= 100 && valueToUpdate.length <= 16)) {
                    return valueToUpdate;
                }
            }
        } else {
            let validated = value?.replace(".", "").length > 16 ? false : true;
            let valueToUpdate = value;
            if (validated) {
                return valueToUpdate;
            } else {
                return valueToUpdate.substring(0, 16);
            }
        }
    };

    const handleSave = () => {
        setLoading(true);
        const lead_id = leadDetail.lead_id || "";

        const refinanceInput = {
            lead_id: lead_id.toString(),
            financier_id: computationData.financier_id.toString(),
            loan_amount: computationData.loan_amount,
            pass_through: {
                dealer_incentive_amount: computationData.pass_through.dealer_incentive_amount.toString(),
            },
            incentive_amount_from_financier: {
                di_percentage: computationData.incentive_amount_from_financier.di_percentage.toString(),
                di_amount: computationData.incentive_amount_from_financier.di_amount.toString(),
                di_tax_percentage: computationData.incentive_amount_from_financier.di_tax_percentage.toString(),
                di_tax_amount: computationData.incentive_amount_from_financier.di_tax_amount.toString(),
            },
        };

        if (computationData?.pass_through?.dealer_incentive_amount === "no") {
            refinanceInput.computation_details = {
                incentive_pct: computationData.computation_details.incentive_pct.toString(),
                incentive_amount: computationData.computation_details.incentive_amount.toString(),
                topup_pct: computationData.computation_details.topup_pct.toString(),
                topup_amount: computationData.computation_details.topup_amount.toString(),
                total_incentive_pct: computationData.computation_details.total_incentive_pct.toString(),
                total_incentive_amount: computationData.computation_details.total_incentive_amount.toString(),
                incentive_tax_pct: computationData.computation_details.incentive_tax_pct.toString(),
                incentive_net_tax: computationData.computation_details.incentive_net_tax.toString(),
            };
        }

        if (refinanceInput.pass_through.dealer_incentive_amount === "yes") {
            refinanceInput.pass_through.payout_percentage = computationData.pass_through.payout_percentage.toString();
            refinanceInput.pass_through.tax_percentage = computationData.pass_through.tax_percentage.toString();
            refinanceInput.pass_through.payout_amount = computationData.pass_through.payout_amount.toString();
        }

        const variables = {
            refinanceInput: refinanceInput,
        };

        executeGraphQLMutation(SAVE_REFINANCE_CALCULATION, variables, client)
            .then((response) => {
                if (response?.data) {
                    toast.success(response.data.saveRefinanceCalculation.message);
                    dispatch(getUCRFLeadDetail(lead_id, client));
                    dispatch(getUCRFFormFillStatus({ lead_id }, client));
                    navigateToNext();
                }
            })
            .catch((error) => {
                toast.error(error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const sectionInfo = { section: "Computation Generated", sub_section: "Dealer Incentive" };

    const checkSaveEnable = (leadDetail) => {
        let markAsFreezed = 0;
        let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
        let user_page_access = IS_USER_ACCESS_PAGE("refinanceComputation", "ucrf-computation-generated")?.is_edit_access;
        let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to);
        let nextEnable =
            leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex((e) => e == COMPUTATION_GENERATED) > -1
                ? true
                : false;
        if (nextEnable) {
            setIsNextEnabled(true);
            setIsSaveDisabled(true)
        } else if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed && !nextEnable) {
            setIsSaveDisabled(false);
            setIsNextEnabled(false)
        } else {
            setIsSaveDisabled(true)
            setIsNextEnabled(false)
        }
        if (!user_edit_access && user_page_access) {
            setIsRemarkDisabled(false);
        }
    };

    return (
        <>
            <div className="image-form-outer">
                {loading ? <Loader /> : ""}
                <div className="image-tab-panel">
                    <DocumentGallery documentCategoryList={documentCategoryList} />
                </div>
                <div className="form-tab-right-panel">
                    <div className="lead-detail-from-outer">
                        <div className={" switch-toggle"}>
                            <div className="lead-form-heading">
                                <span className="main-heading">Computation Generated </span>
                                <h2>Dealer Incentive</h2>
                            </div>
                            <Formsy className="lead-form-filed soft-approval-filled-outer" autoComplete="off" ref={formRef} onSubmit={handleSave}>
                                <Accordion allowZeroExpanded>
                                    <div className="">
                                        <AccordionItem className="acc-item-financer">
                                            <AccordionItemHeading className="acc-heading-financer">
                                                <AccordionItemButton>
                                                    <div className="f-16">
                                                        <h3 title={"Pass Through Details"}>Pass Through Details</h3>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className="acc-panel-finaner">
                                                <div className="soft-approvel-sec">
                                                    <div className="row">
                                                        <fieldset className="form-filed col-md-12 radio-btn radio-type-txt">
                                                            <label className="form-label">DI Amount Paid By Financier*</label>                                                            
                                                            <div className="custom-control custom-checkbox" key={"yes"}>
                                                                <input
                                                                    id="yes"
                                                                    aria-label={`for Yes`}
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    name={`dealer_incentive_amount`}
                                                                    value={"yes"}
                                                                    checked={computationData?.pass_through?.dealer_incentive_amount === "yes"}
                                                                    onChange={(e) => handleChange(e, "pass_through", "dealer_incentive_amount", "radio")}
                                                                    disabled={isDataFilled}
                                                                    required={true}
                                                                />
                                                                <label htmlFor={"dealer_incentive_amount"} className="custom-control-label">
                                                                    Yes
                                                                </label>
                                                            </div>
                                                            <div className="custom-control custom-checkbox" key={"no"}>
                                                                <input
                                                                    id="no"
                                                                    aria-label={`for No`}
                                                                    type="radio"
                                                                    className="custom-control-input"
                                                                    name={`dealer_incentive_amount`}
                                                                    value={"no"}
                                                                    checked={computationData?.pass_through?.dealer_incentive_amount === "no"}
                                                                    onChange={(e) => handleChange(e, "pass_through", "dealer_incentive_amount", "radio")}
                                                                    disabled={isDataFilled}
                                                                    required={true}
                                                                />
                                                                <label htmlFor={"dealer_incentive_amount"} className="custom-control-label">
                                                                    No
                                                                </label>
                                                            </div>
                                                        </fieldset>

                                                        <fieldset class="form-filed col-md-12 ">
                                                            <FormsyInputField
                                                                id="payout_percentage"
                                                                name="payout_percentage"
                                                                type="text"
                                                                value={computationData?.pass_through?.payout_percentage || ""}
                                                                placeholder=" "
                                                                label={"Direct Dealer Payout from Financier %age"}
                                                                onChange={(e) => {
                                                                    handleChange(e, "pass_through", "payout_percentage", "text");
                                                                }}
                                                                rule={"max16DigitValidation"}
                                                                validationError="Enter Valid Payout Percentage"
                                                                readOnly={computationData?.pass_through?.dealer_incentive_amount === "no"}
                                                                required={computationData?.pass_through?.dealer_incentive_amount === "yes"}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                        <fieldset class="form-filed col-md-12 ">
                                                            <FormsyInputField
                                                                id="tax_percentage"
                                                                name="tax_percentage"
                                                                type="text"
                                                                decimalSeparator="."
                                                                value={computationData?.pass_through?.tax_percentage || ""}
                                                                placeholder=" "
                                                                label={"Tax %age"}
                                                                onChange={(e) => {
                                                                    handleChange(e, "pass_through", "tax_percentage", "text");
                                                                }}
                                                                // validations={"percentageValidation"}
                                                                rule={"max16DigitValidation"}
                                                                validationError="Enter Valid Tax Percentage"
                                                                readOnly={computationData?.pass_through?.dealer_incentive_amount === "no"}
                                                                required={computationData?.pass_through?.dealer_incentive_amount === "yes"}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                        <fieldset class="form-filed col-md-12 ">
                                                            <FormsyInputField
                                                                id="payout_amount"
                                                                name="payout_amount"
                                                                type="number-format"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator=","
                                                                decimalSeparator="."
                                                                decimalScale={MAX_DECIMAL}
                                                                value={computationData?.pass_through?.payout_amount || ""}
                                                                placeholder=" "
                                                                label={"Direct Dealer Payout from Financier Amount Net of Tax"}
                                                                onChange={(e) => {
                                                                    handleChange(e, "pass_through", "payout_amount", "number-format");
                                                                }}
                                                                validations={"ucrfMaxAmountLength"}
                                                                validationError="Enter Valid Payout Amount"
                                                                readOnly={true}
                                                                required={computationData?.pass_through?.dealer_incentive_amount === "yes"}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </div>
                                </Accordion>

                                <Accordion allowZeroExpanded>
                                    <div className="">
                                        <AccordionItem className="acc-item-financer">
                                            <AccordionItemHeading className="acc-heading-financer">
                                                <AccordionItemButton>
                                                    <div className="f-16">
                                                        <h3 title={"Incentive Amount From Financier"}>Incentive Amount From Financier</h3>
                                                    </div>
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel className="acc-panel-finaner">
                                                <div className="soft-approvel-sec">
                                                    <div className="row">
                                                        <fieldset className="form-filed col-md-12">
                                                            <FormsyInputField
                                                                id="di_percentage"
                                                                name="di_percentage"
                                                                type="text"
                                                                value={computationData?.incentive_amount_from_financier?.di_percentage || ""}
                                                                placeholder=" "
                                                                label="DI Amount from Financier to Carmudi %age"
                                                                onChange={(e) =>
                                                                    handleChange(e, "incentive_amount_from_financier", "di_percentage", "text")
                                                                }
                                                                rule={"max16DigitValidation"}
                                                                // validations={"percentageValidation"}
                                                                validationError="Enter Valid DI Percentage"
                                                                readOnly={false}
                                                                required={true}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                        <fieldset className="form-filed col-md-12">
                                                            <FormsyInputField
                                                                id="di_amount"
                                                                name="di_amount"
                                                                type="number-format"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator=","
                                                                decimalSeparator="."
                                                                decimalScale={MAX_DECIMAL}
                                                                value={computationData?.incentive_amount_from_financier?.di_amount || ""}
                                                                placeholder=" "
                                                                label="DI Amount from Financier to Carmudi Amount"
                                                                onChange={(e) =>
                                                                    handleChange(e, "incentive_amount_from_financier", "di_amount", "number-format")
                                                                }
                                                                validations={"ucrfMaxAmountLength"}
                                                                validationError="Enter Valid DI Amount"
                                                                readOnly={true}
                                                                required={true}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                        <fieldset className="form-filed col-md-12">
                                                            <FormsyInputField
                                                                id="di_tax_percentage"
                                                                name="di_tax_percentage"
                                                                type="text"
                                                                value={computationData?.incentive_amount_from_financier?.di_tax_percentage || ""}
                                                                placeholder=" "
                                                                label="DI Amount from Financier to Carmudi Tax %age"
                                                                onChange={(e) =>
                                                                    handleChange(e, "incentive_amount_from_financier", "di_tax_percentage", "text")
                                                                }
                                                                // validations={"percentageValidation"}
                                                                rule={"max16DigitValidation"}
                                                                validationError="Enter Valid DI Tax Percentage"
                                                                readOnly={false}
                                                                required={true}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                        <fieldset className="form-filed col-md-12">
                                                            <FormsyInputField
                                                                id="di_tax_amount"
                                                                name="di_tax_amount"
                                                                type="number-format"
                                                                thousandsGroupStyle="thousand"
                                                                thousandSeparator=","
                                                                decimalSeparator="."
                                                                decimalScale={MAX_DECIMAL}
                                                                value={computationData?.incentive_amount_from_financier?.di_tax_amount || ""}
                                                                placeholder=" "
                                                                label="DI Amount from Financier to Carmudi Amount Net of Tax"
                                                                onChange={(e) =>
                                                                    handleChange(e, "incentive_amount_from_financier", "di_tax_amount", "number-format")
                                                                }
                                                                validations={"ucrfMaxAmountLength"}
                                                                validationError="Enter Valid DI Tax Amount"
                                                                readOnly={true}
                                                                required={true}
                                                                showAsterisk={true}
                                                            />
                                                        </fieldset>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </div>
                                </Accordion>
                                {computationData?.pass_through?.dealer_incentive_amount === "no" ? (
                                    <Accordion allowZeroExpanded>
                                        <div className="">
                                            <AccordionItem className="acc-item-financer">
                                                <AccordionItemHeading className="acc-heading-financer">
                                                    <AccordionItemButton>
                                                        <div className="f-16">
                                                            <h3 title={"Computation Details"}>Computation Details</h3>
                                                        </div>
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel className="acc-panel-finaner">
                                                    <div className="soft-approvel-sec">
                                                        <div className="row">
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="incentive_pct"
                                                                    name="incentive_pct"
                                                                    type="text"
                                                                    value={computationData?.computation_details?.incentive_pct || ""}
                                                                    placeholder=" "
                                                                    label="Carmudi's Incentive %age"
                                                                    onChange={(e) => handleChange(e, "computation_details", "incentive_pct", "text")}
                                                                    // validations={"percentageValidation"}
                                                                    rule={"max16DigitValidation"}
                                                                    validationError="Enter Valid Incentive Percentage"
                                                                    readOnly={false}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="incentive_amount"
                                                                    name="incentive_amount"
                                                                    type="number-format"
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    decimalScale={MAX_DECIMAL}
                                                                    value={computationData?.computation_details?.incentive_amount || ""}
                                                                    placeholder=" "
                                                                    label="Carmudi Incentive Amount"
                                                                    onChange={(e) =>
                                                                        handleChange(e, "computation_details", "incentive_amount", "number-format")
                                                                    }
                                                                    validations={"ucrfMaxAmountLength"}
                                                                    validationError="Enter Valid Incentive Amount"
                                                                    readOnly={true}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="topup_pct"
                                                                    name="topup_pct"
                                                                    type="text"
                                                                    value={computationData?.computation_details?.topup_pct || ""}
                                                                    placeholder=" "
                                                                    label="Carmudi Top-up %"
                                                                    onChange={(e) => handleChange(e, "computation_details", "topup_pct", "text")}
                                                                    // validations={"percentageValidation"}
                                                                    rule={"max16DigitValidation"}
                                                                    validationError="Enter Valid Top-up Percentage"
                                                                    readOnly={false}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="topup_amount"
                                                                    name="topup_amount"
                                                                    type="number-format"
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    decimalScale={MAX_DECIMAL}
                                                                    value={computationData?.computation_details?.topup_amount || 0}
                                                                    placeholder=" "
                                                                    label="Carmudi Top-up Amount"
                                                                    onChange={(e) =>
                                                                        handleChange(e, "computation_details", "topup_amount", "number-format")
                                                                    }
                                                                    validations={"ucrfMaxAmountLength"}
                                                                    validationError="Enter Valid Top-up Amount"
                                                                    readOnly={true}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>

                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="total_incentive_pct"
                                                                    name="total_incentive_pct"
                                                                    type="number-format"
                                                                    value={computationData?.computation_details?.total_incentive_pct || ""}
                                                                    placeholder=" "
                                                                    label="Total Carmudi Incentive %age"
                                                                    onChange={(e) =>
                                                                        handleChange(e, "computation_details", "total_incentive_pct", "number-format")
                                                                    }
                                                                    validations={"percentageValidation"}
                                                                    validationError="Enter Valid Total Incentive Percentage"
                                                                    readOnly={true}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="total_incentive_amount"
                                                                    name="total_incentive_amount"
                                                                    type="number-format"
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    decimalScale={MAX_DECIMAL}
                                                                    value={computationData?.computation_details?.total_incentive_amount || ""}
                                                                    placeholder=" "
                                                                    label="Total Carmudi Incentive Amount"
                                                                    onChange={(e) =>
                                                                        handleChange(e, "computation_details", "total_incentive_amount", "number-format")
                                                                    }
                                                                    validations={"ucrfMaxAmountLength"}
                                                                    validationError="Enter Valid Total Incentive Amount"
                                                                    readOnly={true}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>

                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="incentive_tax_pct"
                                                                    name="incentive_tax_pct"
                                                                    type="text"
                                                                    value={computationData?.computation_details?.incentive_tax_pct || ""}
                                                                    placeholder=" "
                                                                    label="Incentive Tax %age"
                                                                    onChange={(e) => handleChange(e, "computation_details", "incentive_tax_pct", "text")}
                                                                    // validations={"percentageValidation"}
                                                                    validationError="Enter Valid Incentive Tax Percentage"
                                                                    rule={"max16DigitValidation"}
                                                                    readOnly={false}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                            <fieldset className="form-filed col-md-6">
                                                                <FormsyInputField
                                                                    id="incentive_net_tax"
                                                                    name="incentive_net_tax"
                                                                    type="number-format"
                                                                    thousandsGroupStyle="thousand"
                                                                    thousandSeparator=","
                                                                    decimalSeparator="."
                                                                    decimalScale={MAX_DECIMAL}
                                                                    value={computationData?.computation_details?.incentive_net_tax || ""}
                                                                    placeholder=" "
                                                                    label="Total Carmudi Incentive Net of Tax"
                                                                    onChange={(e) =>
                                                                        handleChange(e, "computation_details", "incentive_net_tax", "number-format")
                                                                    }
                                                                    validations={"ucrfMaxAmountLength"}
                                                                    validationError="Enter Valid Net of Tax Percentage"
                                                                    readOnly={true}
                                                                    required={true}
                                                                    showAsterisk={true}
                                                                />
                                                            </fieldset>
                                                        </div>
                                                    </div>
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        </div>
                                    </Accordion>
                                ) : null}
                            </Formsy>
                        </div>
                        <div className="btn-save-remarks">
                            {isSaveDisable ? (
                                <>
                                    {isNextEnable ? (
                                        <button type="button" className="btn-primary" onClick={() => navigateToNext()}>
                                            Next
                                        </button>
                                    ) : (
                                        <span className="span-disabled">Save</span>
                                    )}
                                </>
                            ) : (
                                <button aria-label="save naxt" type="submit" onClick={submitForm} className="btn-primary">
                                    Save & Next
                                </button>
                            )}
                            <button aria-label="remarks history" type="button" className="btn-line" onClick={showModalViewTimeLine}>
                                Remarks
                                <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="view-timeline-popup">
                    <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory
                            showremarkform={showremarkform}
                            markAsFreezed={getContext.markAsFreezedNewFlow}
                            sectionInfo={sectionInfo}
                            isRemarkDisable={isRemarkDisable}
                        />
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default RefinanceComputationGenerated;
