import React, { useState, useEffect } from "react";
import { GET_LOAN_EXPECTED_FINANCIER_LIST } from "../../../services/refinance/leads.gql";
import { executeGraphQLQuery,executeGraphQLMutation } from "../../../services/refinance/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { NO_TOUCH_POLICY_FINANCIER } from "../../../config/constants";
import { toast } from "react-toastify";
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from "../../../services/customer.gql";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";
import { getUCRFLeadDetail } from "../../../store/action/allAction";
import { useDispatch } from "react-redux";

const LoanExpectedQuoteGeneration = (props) => {
  const dispatch = useDispatch();
  const [quotesArr, setQuotesArr] = useState([]);
  const [selectedFinanciers, setSelectedFinanciers] = useState([]);
  const client = useApolloClient();
  useEffect(() => {
    const fetchFinancierList = async () => {
      if (quotesArr.length === 0 && props.lead_id) {
        const result = await executeGraphQLQuery(
          GET_LOAN_EXPECTED_FINANCIER_LIST(props.lead_id),
          client
        );
        setQuotesArr(result?.data?.refinanceQuote || []);
      }
    };

    const fetchSelectedFinanciers = () => {
      const selected = quotesArr
        .filter((quote) => quote.is_selected === "1")
        .map((quote) => quote.financier_id);
      setSelectedFinanciers(selected);
    };

    fetchFinancierList();
    if (quotesArr.length) {
      fetchSelectedFinanciers();
    }
  }, [props.lead_id, quotesArr.length, props.fetchNewQuote]);


  useEffect(() => {
    const fetchFinancierList = async () => {
      if (props.activeTab === "second") {
        const result = await executeGraphQLQuery(
          GET_LOAN_EXPECTED_FINANCIER_LIST(props.lead_id),
          client
        );
        setQuotesArr(result?.data?.refinanceQuote || []);
      }
    };

    const fetchSelectedFinanciers = () => {
      const selected = quotesArr
        .filter((quote) => quote.is_selected === "1")
        .map((quote) => quote.financier_id);
      setSelectedFinanciers(selected);
    };

    fetchFinancierList();
    if (quotesArr.length) {
      fetchSelectedFinanciers();
    }
  }, [props.activeTab]);

  

  const handleCheckBoxChange = (e, financier_id) => {
    const isChecked = e.target.checked;
    let updatedFinanciers = [...selectedFinanciers];

    if (isChecked) {
      const anyValueExists = NO_TOUCH_POLICY_FINANCIER.some((nf) =>
        updatedFinanciers.includes(nf)
      );
      if (
        !anyValueExists ||
        !NO_TOUCH_POLICY_FINANCIER.includes(financier_id)
      ) {
        updatedFinanciers.push(financier_id);
      }
    } else {
      updatedFinanciers = updatedFinanciers.filter((id) => id !== financier_id);
    }

    if (updatedFinanciers.length <= 4) {
      setSelectedFinanciers(updatedFinanciers);
    } else {
      toast.error("You cannot select more than four financiers.");
    }
  };

  const saveSelectedFinancier = (event) => {
    // console.log("=====selectedFinanciers=====", selectedFinanciers);

    const financier_list = selectedFinanciers.map(ele => ({
      "financier_id": ele
    }));
    event.preventDefault();
    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS;
        let variables = {
            lead_data: {
                created_by:  0,
                updated_by:  0,
                tab_id: 3,
                sub_tab_id: 3,
                lead_id: Number(props.lead_id),
                auto_save: 0,
                financier_quote_generation: financier_list
            },
        };

        executeGraphQLMutation(mutation, variables, client)
        .then((response) => {
            if (response?.data) {
                let resp = response?.data?.updateLeadDetails || null;
                if (resp && !resp.lead_id) {
                    throw new Error(resp.error || "Something went wrong");
                }
                toast.success("Success");
                dispatch(getUCRFLeadDetail(props.lead_id, client));
                // dispatch(getUCRFFormFillStatus({ lead_id }, client));
                props.handleTabChange(this,"third")
            }
        })
        .catch((error) => {
            toast.error(error.message);
        })
        .finally(() => {
            // setLoading(false);
        });
  };
  let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-basic-details")?.is_edit_access
  return (
    <>
      {quotesArr.length > 0 ? (
        quotesArr.map((quote) => (
          <div className="financer-quote-card" key={quote.financier_id}>
            <div className="financer-deatil">
              <div className="financer-name-logo d-flex">
                <div className="finanacer-logo">
                  <img src={quote.financier_logo} alt="brand logo" />
                </div>
                <div className="financer-car-detail">
                  <span>{quote.financier_name}</span>
                  <label>{quote.mmv}</label>
                </div>
              </div>
              <div className="check-approve-status">
                <div className="material flexdisplay">
                  <div>
                    {quote.approval === 1 && (
                      <span className="soft-approved">Soft Approved</span>
                    )}
                    {quote.approval === 2 && (
                      <span className="rejected">Rejected</span>
                    )}
                  </div>
                  {quote.approval !== 2 && 
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      name={quote.financier_id}
                      aria-label="same as CRA"
                      value={quote.financier_id}
                      onChange={(e) =>
                        handleCheckBoxChange(e, quote.financier_id)
                      }
                      checked={selectedFinanciers.includes(quote.financier_id)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={quote.name}
                    ></label>
                  </div>
                  }
                </div>
              </div>
            </div>
            <div className="car-finance-detail quote-selection">
              <ul>
                <li>
                  <span>Fair Market Value of Car</span>
                  <label>Rp {quote.fmv_of_car}</label>
                </li>
                <li>
                  <span>Amount Financed</span>
                  <label>Rp {quote.amount_financed}</label>
                </li>
                <li>
                  <span>Tenure</span>
                  <label>{quote.tenure}</label>
                </li>
                <li>
                  <span>Installment Amount</span>
                  <label>Rp {quote.instalment_amount}</label>
                </li>
                <li>
                  <span>Interest Rate</span>
                  <label>{quote.interest_rate}%</label>
                </li>
              </ul>
            </div>
          </div>
        ))
      ) : (
        <span>No Quotes Available</span>
      )}
    {user_page_access &&
    <>
      {quotesArr.length>0 && !props.isSaveDisable &&
      <button
        type="button"
        className="btn-primary"
        onClick={saveSelectedFinancier}
      >
        Save & Continue
      </button>
      }
      </>
    }
    </>
  );
};

export default LoanExpectedQuoteGeneration;

// const areEqual = (prevProps, nextProps) => {
//   return prevProps.carDetails === nextProps.carDetails;
// };

// export default React.memo(LoanExpectedQuoteGeneration, areEqual);
