import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../services/refinance/executeGraphQLQuery";
import {
  GET_MMV_ALL,
  GET_LOAN_EXPECTED_VEHICLE_DETAILS,
  QUOTE_SELECTION_PRE_FILLED,
} from "../../../services/refinance/leads.gql";
import { toast } from "react-toastify";
import Formsy from "formsy-react";
import NoQuote from "./NoQuote";
import LoanQuoteTabs from "./LoanQuoteTabs";
import LoanExpectedRightPanel from "./LoanExpectedRightPanel";
import {
  populateMap,
  DEPENDENCIES,
  isCarDetailsFilled,
  ALL_OPTIONS,
  removeTypename,
} from "./utils";
import {
  getUCRFFormFillStatus,
  getUCRFLeadDetail,
} from "../../../store/action/allAction";
import { UCRF_UPDATE_CUSTOMER_BASIC_DETAILS } from "../../../services/customer.gql";
import crypto from "../../../config/crypto";
import LoanExpectedQuoteSummary from "./LoanExpectedQuoteSummary";
import Loader from "../../elements/Loader";
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from "../../../config/constants";
import { LOST_STATUS, SENT_TO_FINANCIER } from "../../../config/refinance/constant";

const RefinanceLoanExpected = (props) => {
  let { lead_id } = useParams();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { master_data_ucrf, leadDetail } = useSelector(
    ({ lead, masterdataucrf }) => ({
      master_data_ucrf: masterdataucrf?.master_data_ucrf || {},
      lead,
      leadDetail: lead?.ucrfLeadDetail || {},
    })
  );
  const dispatch = useDispatch();
  // const formRef = useRef();
  const [displayQuote, setDisplayQuote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carDetails, setCarDetails] = useState({});
  const [loanDetails, setLoanDetails] = useState(new Map());
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [makeMap, setMakeMap] = useState(new Map());
  const [modelMap, setModelMap] = useState(new Map());
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [versionOptions, setVersionOptions] = useState([]);
  const [activeTab, setActiveTab] = useState("first");
  const [allCarDetailsFilled, setAllCarDetailsFilled] = useState(false);
  // const [allLoanDetailsFilled, setAllLoanDetailsFilled] = useState(false);
  const [tenure, setTenure] = useState([]);
  const [currentLeadId, setCurrentLeadId] = useState(crypto.decode(lead_id));
  const [currentFmv, setCurrentFmv] = useState(0);
  const [validateFMV, setValidateFMV] = useState("");
  const [selectionquotes, setSelectionquotes] = useState([]);
  const [editQuoteEnabled, seteditQuoteEnabled] = useState(true);
  const [olderFMV, setOlderFMV] = useState("0");
  const [fetchNewQuote, setFetchNewQuote] = useState(false);
  const [isSaveDisable, setIsSaveDisabled] = useState(true)

  const user_information = useSelector((state) => state.user.user_information);
  const UserInfo = JSON.parse(user_information);
  let logged_user_id = UserInfo && UserInfo.id ? UserInfo.id : 0;

  const showQuotes = (val) => {
    setDisplayQuote(val);
  };

  const getMmvAll = async (category_id) => {
    // setLoading(true);
    try {
      const result = await executeGraphQLQuery(
        GET_MMV_ALL(category_id),
        client
      );
      const mmvData = result?.data?.getMmvAll;

      const quote_form = await executeGraphQLQuery(
        GET_LOAN_EXPECTED_VEHICLE_DETAILS(currentLeadId),
        client
      );
      // console.log("=========quote_form=======", quote_form.data.getLeadVehicalDetails);
      const financier_form = quote_form?.data?.getLeadVehicalDetails ?? {};

      setOlderFMV(financier_form?.car_fmv ?? "0");

      // setTenure(financier_form?.tenure_in_month ?? 0);
      if (!mmvData) {
        throw new Error("No MMV data received");
      }

      const newCategoryMap = populateMap(mmvData.make ?? [], "category_id");
      if (category_id === "1") setMakeOptions(mmvData.make);

      const newMakeMap = populateMap(mmvData.model ?? [], "mk_id");
      const newModelMap = populateMap(mmvData.version ?? [], "p_m_id");
      setMakeMap(newMakeMap);
      setModelMap(newModelMap);

      ["vehicle_usage", "vehicle_type", "make_year"].forEach((key) => {
        ALL_OPTIONS[key] = master_data_ucrf[key] ?? [];
      });

      const initialCarDetails = Object.keys(carDetails).length
        ? carDetails
        : financier_form;
      if (!initialCarDetails) {
        return null;
      }

      const usage = initialCarDetails.vehicle_usage;
      const usageId = ALL_OPTIONS.vehicle_usage.find(
        (opt) => opt.value === usage
      )?.id;
      const updatedVehicleTypeOptions = usageId
        ? ALL_OPTIONS.vehicle_type.filter(
            (type) => type.vehicle_usage === parseInt(usageId)
          )
        : [];
      setVehicleTypeOptions(updatedVehicleTypeOptions);

      const newCategoryId = category_id === null ? "1" : String(category_id);
      const updatedMakeOptions = Object.values(
        newCategoryMap.get(newCategoryId) ?? []
      );
      let updatedModelOptions, updatedVersionOptions;
      if (parseInt(initialCarDetails?.make_id) === 0) {
        const otherOptions = [{ id: "0", value: "0", label: "Other" }];
        updatedModelOptions = otherOptions;
        updatedVersionOptions = otherOptions;
      } else {
        updatedModelOptions = Object.values(
          newMakeMap.get(String(initialCarDetails?.make_id)) ?? []
        );
        updatedVersionOptions = Object.values(
          newModelMap.get(String(initialCarDetails?.model_id)) ?? []
        );
      }

      if (!updatedModelOptions.length) {
        updatedModelOptions = Object.values(
          newMakeMap.get(String(initialCarDetails?.make_id)) ?? []
        );
      }

      setMakeOptions(updatedMakeOptions);
      setModelOptions(updatedModelOptions);
      setVersionOptions(updatedVersionOptions);
      // setAllCarDetailsFilled(isCarDetailsFilled(initialCarDetails));
      // console.log("=====initialCarDetails====", initialCarDetails);
      if (initialCarDetails?.car_fmv) {
        setCurrentFmv(initialCarDetails.car_fmv);
      }
      setCarDetails(removeTypename(initialCarDetails));
      setLoading(false);
      return {
        updatedMakeOptions,
        updatedModelOptions,
        updatedVersionOptions,
        updatedVehicleTypeOptions,
      };
    } catch (error) {
      console.error("Error in getMmvAll:", error);
      toast.error("Something went wrong, please try again");
      return null;
    } finally {
      setLoading(false);
    }
  };

  // const submitForm = () => {
  //     if (formRef.current) {
  //         formRef.current.validateForm();
  //         if (formRef.current.state.isValid) {
  //             formRef.current.submit();
  //         } else {
  //             toast.error("Please correct the errors in the form");
  //         }
  //     }
  // };

  const handleTabChange = (event, tabName) => {
    setActiveTab(tabName);
  };

  const handleCarDetailChange = async (
    event,
    fieldName,
    fieldType,
    fmv = 0
  ) => {
    // console.log("====debug event====", event);
    // console.log("====debug fieldName====", fieldName);
    // console.log("====debug fieldType====", fieldType);
    let tempCarDetails = { ...carDetails };
    let tempMakeOptions = [...makeOptions];
    let tempModelOptions = [...modelOptions];
    let tempVersionOptions = [...versionOptions];
    let tempVehicleTypeOptions = [...vehicleTypeOptions];

    if (fieldType === "select" && DEPENDENCIES[fieldName]) {
      DEPENDENCIES[fieldName].forEach((field) => {
        tempCarDetails[field] = "";
      });
    }

    const nameFields = {
      make_id: "make_name",
      model_id: "model_name",
      variant_id: "variant_name",
      tenure_in_month: "tenure_in_month",
      car_fmv: "car_fmv",
      vehicle_type: "vehicle_type",
    };
    if (nameFields[fieldName]) {
      tempCarDetails[nameFields[fieldName]] = event.label;
    }

    if (nameFields[fieldName] == "vehicle_type") {
      tempCarDetails["r_vehicle_type"] = event.label;
    }

    // if(nameFields[fieldName] == "car_fmv"){
    //     console.log("====debug event value====", event.value);
    //     tempCarDetails["car_fmv"] = event.value;
    // }

    // console.log("=====debug tempCarDetails====", tempCarDetails);

    tempCarDetails[fieldName] =
      fieldType === "select" ? event?.value ?? "" : event?.target?.value ?? "";

    switch (fieldName) {
      case "vehicle_usage":
        const usageId = ALL_OPTIONS.vehicle_usage.find(
          (opt) => opt.value === tempCarDetails.vehicle_usage
        )?.id;
        tempVehicleTypeOptions = usageId
          ? ALL_OPTIONS.vehicle_type.filter(
              (type) => type.vehicle_usage === parseInt(usageId)
            )
          : [];
        if (
          !tempVehicleTypeOptions.some(
            (opt) => opt.value === tempCarDetails.vehicle_type
          )
        ) {
          tempCarDetails.vehicle_type = "";
          tempCarDetails.vehicle_category = "";
        }
        break;

      case "vehicle_type":
        tempCarDetails["vehicle_category"] = event?.vehicle_category;
        const allOptions = await getMmvAll(event?.vehicle_category);
        tempMakeOptions = allOptions.updatedMakeOptions;
        tempModelOptions = [];
        tempVersionOptions = [];
        break;

      case "make_id":
        if (event?.value === "0") {
          tempCarDetails["model_id"] = "0";
          tempCarDetails["variant_id"] = "0";
          tempModelOptions = [{ id: "0", value: "0", label: "Other" }];
          tempVersionOptions = [{ id: "0", value: "0", label: "Other" }];
        } else {
          const modelData = makeMap.get(String(event?.id ?? "")) ?? {};
          tempModelOptions = [...Object.values(modelData)];
          tempVersionOptions = [];
        }
        break;

      case "model_id":
        if (tempCarDetails["model_id"] === "0") {
          tempCarDetails["variant_id"] = "0";
          tempVersionOptions = [{ id: "0", value: "0", label: "Other" }];
        } else {
          const versionData = modelMap.get(String(event?.id ?? "")) ?? {};
          tempVersionOptions = [...Object.values(versionData)];
          break;
        }
        break;
      default:
    }

    // console.log("======tempCarDetails[car_fmv]", tempCarDetails["car_fmv"]);
    // console.log("======event?.id=======", event?.id);
    // console.log("======currentFmv=======", currentFmv);
    // if(tempCarDetails["car_fmv"] !==""){
    //     setValidateFMV(tempCarDetails["car_fmv"])
    // }
    if (tempCarDetails["car_fmv"] == "" && event?.id) {
      tempCarDetails["car_fmv"] = currentFmv;
    }

    // if(tempCarDetails["car_fmv"] == "" && validateFMV!==""){
    //     tempCarDetails["car_fmv"] = validateFMV;
    // }

    /* for save & continou button code start here*/
    const strValue = tempCarDetails["car_fmv"]?.toString();
    const length = strValue?.length ?? 0;
    if (length <= 21 && validateFMV !== "") {
      setValidateFMV(tempCarDetails["car_fmv"]);
    } else if (length > 21) {
      tempCarDetails["car_fmv"] = validateFMV;
      setCurrentFmv(validateFMV);
    }

    /* for save & continou button code end here*/

    setMakeOptions(tempMakeOptions);
    setModelOptions(tempModelOptions);
    setVersionOptions(tempVersionOptions);
    setVehicleTypeOptions(tempVehicleTypeOptions);
    setCarDetails(tempCarDetails);
    // console.log("====carDetails validate====", isCarDetailsFilled(tempCarDetails));
    setAllCarDetailsFilled(isCarDetailsFilled(tempCarDetails));
  };

  const handleSave = (event) => {
    event.preventDefault();
    // alert('ok1');
    // setLoading(true);
    const lead_id = leadDetail.lead_id || "";
    const preparedCarDetails = {
      vehicle_usage: carDetails.vehicle_usage,
      vehicle_type: Number(carDetails.vehicle_type),
      vehicle_category: carDetails.vehicle_category,
      make_id: carDetails.make_id,
      make_name: carDetails.make_name,
      model_id: carDetails.model_id,
      model_name: carDetails.model_name,
      variant_name: carDetails.variant_name,
      variant_id: Number(carDetails.variant_id),
      make_year: Number(carDetails.make_year),
      tenure_in_month: Number(carDetails.tenure_in_month),
      car_fmv:
        typeof carDetails?.car_fmv === "number"
          ? carDetails.car_fmv.toString()
          : Number(carDetails.car_fmv?.replace(/,/g, "")).toString(),
    };

    /* const preparedLoanDetails = Array.from(loanDetails.entries()).map(([financierId, details]) => ({
            financier_id: financierId,
            loan_details: {
                ...details.loan_details,
                term_in_months: parseInt(details?.loan_details?.term_in_months),
            },
            standard_deductions: details.standard_deductions,
            additional_deductions: details.additional_deductions,
            loan_calculations: details.loan_calculations,
            additional_loan_info: details.additional_loan_info,
        })); */

    let mutation = UCRF_UPDATE_CUSTOMER_BASIC_DETAILS;
    let variables = {
      lead_data: {
        created_by: logged_user_id || 0,
        updated_by: logged_user_id || 0,
        tab_id: 3,
        sub_tab_id: 3,
        lead_id,
        auto_save: 0,
        financier_form: preparedCarDetails,
      },
    };

    executeGraphQLMutation(mutation, variables, client)
      .then((response) => {
        if (response?.data) {
          let resp = response?.data?.updateLeadDetails || null;
          if (resp && !resp.lead_id) {
            throw new Error(resp.error || "Something went wrong");
          }
          toast.success("Success");
          dispatch(getUCRFLeadDetail(lead_id, client));
          dispatch(getUCRFFormFillStatus({ lead_id }, client));
          setActiveTab("second");
          // console.log("=====debug2 olderFMV=====", olderFMV);
          // console.log("=====debug2 preparedCarDetails.car_fmv=====", preparedCarDetails.car_fmv);
          if(olderFMV != preparedCarDetails.car_fmv){
            setFetchNewQuote(true)
          }
          // console.log();
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const setEditQuote = () => {
    // alert('ok');
    seteditQuoteEnabled(true);
  }
  const setEditQuote2 = () => {
    // alert('ok');
    seteditQuoteEnabled(false);
    setActiveTab('first')
  }

  useEffect(() => {
    getMmvAll(
      leadDetail?.financier_loan_approval?.car_details?.vehicle_category || null
    );
    const loanDetails = leadDetail?.financier_loan_approval?.loan_details;
    if (loanDetails) {
      const tempMap = new Map();
      // (removeTypename(loanDetails) ?? []).forEach((data) => {
      //     const tempData = JSON.parse(JSON.stringify(data));
      //     const [financierObject] = master_data_ucrf?.financier_list?.filter((item) => item.id === data.financier_id) ?? [];
      //     tempData.financier_name = financierObject?.financier_name ?? "";
      //     tempMap.set(data.financier_id, tempData);
      // });
      // setAllLoanDetailsFilled(isLoanDetailsFilled(tempMap));
      setLoanDetails(tempMap);
      checkSaveEnable(leadDetail)
    }
  }, [leadDetail]);

  const checkSaveEnable = (leadDetail) => {
    let markAsFreezed = leadDetail?.customer_timeline_status_ids?.length && leadDetail.customer_timeline_status_ids.findIndex(e => e == SENT_TO_FINANCIER) > -1 ? true : false
    let leadLost = leadDetail?.basic_details?.refinance_status ? leadDetail?.basic_details?.refinance_status === LOST_STATUS.toString() : false;
    let user_page_access = IS_USER_ACCESS_PAGE("refinanceLoanDetail", "ucrf-basic-details")?.is_edit_access
    let user_edit_access = CHECK_ASSIGN_USER(leadDetail.assigned_to)
    if (!user_edit_access && user_page_access && !leadLost && !markAsFreezed) {
      setIsSaveDisabled(false)
    } else {
      setIsSaveDisabled(true)
    }
  }

  useEffect(() => {
    setTenure(master_data_ucrf.tenure_in_month);
  }, []);

  useEffect(() => {
    if (currentFmv !== "") {
      setCurrentFmv(currentFmv);
    }
  }, [currentFmv]);

  useEffect(() => {
    async function getValue() {
      // setLoading(true);
      let getquotes = [];
      const pre_field_result = await executeGraphQLQuery(
        QUOTE_SELECTION_PRE_FILLED(currentLeadId),
        client
      );
      getquotes =
        pre_field_result?.data?.getFinancierPreFillData?.quote_financier_list ||
        [];
      // console.log("====== debug quotes=====", getquotes);
      setSelectionquotes(getquotes);
      setLoading(false);
      if(getquotes.length>0)
      seteditQuoteEnabled(false)
    }
    if(!editQuoteEnabled)
    getValue();
  }, [editQuoteEnabled]);


  useEffect(() => {
    async function getValue() {
      // setLoading(true);
      let getquotes = [];
      const pre_field_result = await executeGraphQLQuery(
        QUOTE_SELECTION_PRE_FILLED(currentLeadId),
        client
      );
      getquotes =
        pre_field_result?.data?.getFinancierPreFillData?.quote_financier_list ||
        [];
      // console.log("====== debug quotes=====", getquotes);
      setSelectionquotes(getquotes);
      setLoading(false);
      if(getquotes.length>0)
      seteditQuoteEnabled(false)
    }
    getValue();
  }, []);

  useEffect(() => {

  },[editQuoteEnabled])

  

  // console.log("====LoanExpected carDetails====", carDetails);
  // console.log("====LoanExpected allCarDetailsFilled====", allCarDetailsFilled);
  // console.log("====LoanExpected activeTab====", activeTab);
  // console.log("====== debug2 quotes 2=====", selectionquotes);
  // console.log("====== debug2 quotes 2=====", selectionquotes.length);
  // console.log("====== debug2 editQuoteEnabled =====", editQuoteEnabled)

  return (
    <div className=" laon-expect-outer">
      {loading ? <Loader /> :
      <>
      <div className="loan-expect-left-panle">
        {( editQuoteEnabled ||selectionquotes.length == 0 ) && (
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            // onSubmit={handleSave}
            // onValid={() => setAllowSubmit(true)}
            // onInvalid={() => setAllowSubmit(false)}
            // onValidSubmit={(e) => {calculateLoan('calculate','',1);toggleswitch('SUMMARY', 'calculate_loan')}}
          >
            {displayQuote && <NoQuote displayQuote={showQuotes} />}
            {!displayQuote && (
              <div className="loan-form">
                <LoanQuoteTabs
                  carDetails={carDetails}
                  allOptions={ALL_OPTIONS}
                  handleChange={handleCarDetailChange}
                  makeOptions={makeOptions}
                  modelOptions={modelOptions}
                  versionOptions={versionOptions}
                  tenure={tenure}
                  handleSave={handleSave}
                  lead_id={currentLeadId}
                  allCarDetailsFilled={allCarDetailsFilled}
                  vehicleTypeOptions={vehicleTypeOptions}
                  handleTabChange={handleTabChange}
                  currentTab={activeTab}
                  currentFmv={currentFmv}
                  olderFMV={olderFMV}
                  fetchNewQuote={fetchNewQuote}
                  editQuote={setEditQuote}
                  editQuote2={setEditQuote2}
                  isSaveDisable={isSaveDisable}
                  selectionquotes={selectionquotes}
                  
                />
              </div>
            )}
          </Formsy>
        )}

        {selectionquotes.length > 0 && !editQuoteEnabled &&(
          <LoanExpectedQuoteSummary
            selectionquotes={selectionquotes}
            lead_id={currentLeadId}
            editQuote={setEditQuote}
            isSaveDisable={isSaveDisable}
          />
        )}
      </div>

      <div className="loan-expect-right-panel">
        <LoanExpectedRightPanel
          carDetails={carDetails}
          vehicleTypeOptions={vehicleTypeOptions}
        />
      </div>
      </>
      }
    </div>
  );
};

export default RefinanceLoanExpected;
